import { isFeatureFlagEnabled } from 'src/core/Shared/infrastructure/featureFlags'

const hotelVerse: { [key in string]: string } = {
  '7334':
    'https://map.hotelverse.tech/chooseForm?config=eyJob3RlbElkIjoyMTcsImNsaWVudElkIjoiMzMiLCJwcm9kdWN0IjoiQ2hvb3NlWW91clJvb20iLCJjb21tYW5kc0ludGVyZmFjZSI6ImV5SmpiMjF0WVc1a2MwbHVkR1Z5Wm1GalpTSTZleUprYVhOaFlteGxaRUpoWTJ0Q2RYUjBiMjRpT25SeWRXVXNJbTFsZEhKcFkzTWlPblJ5ZFdWOUxDSmhiR2NpT2lKSVV6STFOaUo5IiwiYWNjZXNzVG9rZW5IViI6ImV3b2dJQ0p6YVdkdVlYUjFjbVVpT2lBaU56Y3lNamc1TlRsaVlXTTVOVFF4Wm1Zd05qWmpNbU5oWXpJMk9URTBabUU0WldVMllqSTNZVEUzTXpoa09ERTJabU0xWXpFeU1XSmxOekEwTVRsa09UaGhNMkl4TnpZeU5XVmxOVEEyWkRFNFlUYzJOemc1WldRNFlUQTFaVEV3TjJZM1pHRTVNall6TlRWa1pqY3dZVFJtTldZME5UQXpPR1prTTJKalpEUWlMQW9nSUNKMGFXMWxjM1JoYlhBaU9pQXlNamsyTVRrNE9UZ3pDbjA9IiwiY2hvb3NlRm9ybUJ1dHRvbiI6ZmFsc2UsInNlYXJjaFlvdXJSb29tQnV0dG9uIjpmYWxzZSwidXNlcklzTG9nZ2VkIjpmYWxzZSwiY3VzdG9tQ29uZmlnIjp7ImNhbkJFUmVkaXJlY3QiOm51bGwsImNhbkJFU2VhcmNoIjpudWxsLCJoYXNTdGFydGluZ1ByaWNlQ2F0ZWdvcnkiOm51bGwsImNhbkNob29zZVlvdXJSb29tIjpudWxsLCJjYW5DaGVja291dFJlZGlyZWN0IjpudWxsfSwic291cmNlIjpudWxsfQ==&chooseFormMode=0',
  '7371':
    'https://map.hotelverse.tech/chooseForm?config=eyJob3RlbElkIjoxMjcsImNsaWVudElkIjoiMzMiLCJwcm9kdWN0IjoiQ2hvb3NlWW91clJvb20iLCJjb21tYW5kc0ludGVyZmFjZSI6ImV5SmpiMjF0WVc1a2MwbHVkR1Z5Wm1GalpTSTZleUprYVhOaFlteGxaRUpoWTJ0Q2RYUjBiMjRpT25SeWRXVXNJbTFsZEhKcFkzTWlPblJ5ZFdWOUxDSmhiR2NpT2lKSVV6STFOaUo5IiwiYWNjZXNzVG9rZW5IViI6ImV3b2dJQ0p6YVdkdVlYUjFjbVVpT2lBaU56Y3lNamc1TlRsaVlXTTVOVFF4Wm1Zd05qWmpNbU5oWXpJMk9URTBabUU0WldVMllqSTNZVEUzTXpoa09ERTJabU0xWXpFeU1XSmxOekEwTVRsa09UaGhNMkl4TnpZeU5XVmxOVEEyWkRFNFlUYzJOemc1WldRNFlUQTFaVEV3TjJZM1pHRTVNall6TlRWa1pqY3dZVFJtTldZME5UQXpPR1prTTJKalpEUWlMQW9nSUNKMGFXMWxjM1JoYlhBaU9pQXlNamsyTVRrNE9UZ3pDbjA9IiwiY2hvb3NlRm9ybUJ1dHRvbiI6ZmFsc2UsInNlYXJjaFlvdXJSb29tQnV0dG9uIjpmYWxzZSwidXNlcklzTG9nZ2VkIjpmYWxzZSwiY3VzdG9tQ29uZmlnIjp7ImNhbkJFUmVkaXJlY3QiOm51bGwsImNhbkJFU2VhcmNoIjpudWxsLCJoYXNTdGFydGluZ1ByaWNlQ2F0ZWdvcnkiOm51bGwsImNhbkNob29zZVlvdXJSb29tIjpudWxsLCJjYW5DaGVja291dFJlZGlyZWN0IjpudWxsfSwic291cmNlIjpudWxsfQ==&chooseFormMode=0',
  '7282':
    'https://map.hotelverse.tech/chooseForm?config=eyJob3RlbElkIjoxMDksImNsaWVudElkIjoiMzMiLCJwcm9kdWN0IjoiQ2hvb3NlWW91clJvb20iLCJjb21tYW5kc0ludGVyZmFjZSI6ImV5SmpiMjF0WVc1a2MwbHVkR1Z5Wm1GalpTSTZleUprYVhOaFlteGxaRUpoWTJ0Q2RYUjBiMjRpT25SeWRXVXNJbTFsZEhKcFkzTWlPblJ5ZFdWOUxDSmhiR2NpT2lKSVV6STFOaUo5IiwiYWNjZXNzVG9rZW5IViI6ImV3b2dJQ0p6YVdkdVlYUjFjbVVpT2lBaU56Y3lNamc1TlRsaVlXTTVOVFF4Wm1Zd05qWmpNbU5oWXpJMk9URTBabUU0WldVMllqSTNZVEUzTXpoa09ERTJabU0xWXpFeU1XSmxOekEwTVRsa09UaGhNMkl4TnpZeU5XVmxOVEEyWkRFNFlUYzJOemc1WldRNFlUQTFaVEV3TjJZM1pHRTVNall6TlRWa1pqY3dZVFJtTldZME5UQXpPR1prTTJKalpEUWlMQW9nSUNKMGFXMWxjM1JoYlhBaU9pQXlNamsyTVRrNE9UZ3pDbjA9IiwiY2hvb3NlRm9ybUJ1dHRvbiI6ZmFsc2UsInNlYXJjaFlvdXJSb29tQnV0dG9uIjpmYWxzZSwiY3VzdG9tQ29uZmlnIjp7ImNhbkJFUmVkaXJlY3QiOm51bGwsImNhbkJFU2VhcmNoIjpudWxsLCJoYXNTdGFydGluZ1ByaWNlQ2F0ZWdvcnkiOm51bGwsImNhbkNob29zZVlvdXJSb29tIjpudWxsfX0=&devMode=false',
  '7400':
    'https://map.hotelverse.tech/chooseForm?config=eyJob3RlbElkIjoxMDgsImNsaWVudElkIjoiMzMiLCJwcm9kdWN0IjoiQ2hvb3NlWW91clJvb20iLCJjb21tYW5kc0ludGVyZmFjZSI6ImV5SmpiMjF0WVc1a2MwbHVkR1Z5Wm1GalpTSTZleUprYVhOaFlteGxaRUpoWTJ0Q2RYUjBiMjRpT25SeWRXVXNJbTFsZEhKcFkzTWlPblJ5ZFdWOUxDSmhiR2NpT2lKSVV6STFOaUo5IiwiYWNjZXNzVG9rZW5IViI6ImV3b2dJQ0p6YVdkdVlYUjFjbVVpT2lBaU56Y3lNamc1TlRsaVlXTTVOVFF4Wm1Zd05qWmpNbU5oWXpJMk9URTBabUU0WldVMllqSTNZVEUzTXpoa09ERTJabU0xWXpFeU1XSmxOekEwTVRsa09UaGhNMkl4TnpZeU5XVmxOVEEyWkRFNFlUYzJOemc1WldRNFlUQTFaVEV3TjJZM1pHRTVNall6TlRWa1pqY3dZVFJtTldZME5UQXpPR1prTTJKalpEUWlMQW9nSUNKMGFXMWxjM1JoYlhBaU9pQXlNamsyTVRrNE9UZ3pDbjA9IiwiY2hvb3NlRm9ybUJ1dHRvbiI6ZmFsc2UsInNlYXJjaFlvdXJSb29tQnV0dG9uIjpmYWxzZSwiY3VzdG9tQ29uZmlnIjp7ImNhbkJFUmVkaXJlY3QiOm51bGwsImNhbkJFU2VhcmNoIjpudWxsLCJoYXNTdGFydGluZ1ByaWNlQ2F0ZWdvcnkiOm51bGwsImNhbkNob29zZVlvdXJSb29tIjpudWxsfX0=&devMode=false',
  '7337':
    'https://map.hotelverse.tech/chooseForm?config=eyJob3RlbElkIjoxMDcsImNsaWVudElkIjoiMzMiLCJwcm9kdWN0IjoiQ2hvb3NlWW91clJvb20iLCJjb21tYW5kc0ludGVyZmFjZSI6ImV5SmpiMjF0WVc1a2MwbHVkR1Z5Wm1GalpTSTZleUprYVhOaFlteGxaRUpoWTJ0Q2RYUjBiMjRpT25SeWRXVXNJbTFsZEhKcFkzTWlPblJ5ZFdWOUxDSmhiR2NpT2lKSVV6STFOaUo5IiwiYWNjZXNzVG9rZW5IViI6ImV3b2dJQ0p6YVdkdVlYUjFjbVVpT2lBaU56Y3lNamc1TlRsaVlXTTVOVFF4Wm1Zd05qWmpNbU5oWXpJMk9URTBabUU0WldVMllqSTNZVEUzTXpoa09ERTJabU0xWXpFeU1XSmxOekEwTVRsa09UaGhNMkl4TnpZeU5XVmxOVEEyWkRFNFlUYzJOemc1WldRNFlUQTFaVEV3TjJZM1pHRTVNall6TlRWa1pqY3dZVFJtTldZME5UQXpPR1prTTJKalpEUWlMQW9nSUNKMGFXMWxjM1JoYlhBaU9pQXlNamsyTVRrNE9UZ3pDbjA9IiwiY2hvb3NlRm9ybUJ1dHRvbiI6ZmFsc2UsInNlYXJjaFlvdXJSb29tQnV0dG9uIjpmYWxzZSwiY3VzdG9tQ29uZmlnIjp7ImNhbkJFUmVkaXJlY3QiOm51bGwsImNhbkJFU2VhcmNoIjpudWxsLCJoYXNTdGFydGluZ1ByaWNlQ2F0ZWdvcnkiOm51bGwsImNhbkNob29zZVlvdXJSb29tIjpudWxsfX0=&devMode=false',
  '36457':
    'https://map.hotelverse.tech/chooseForm?config=eyJob3RlbElkIjoxMDYsImNsaWVudElkIjoiMzMiLCJwcm9kdWN0IjoiQ2hvb3NlWW91clJvb20iLCJjb21tYW5kc0ludGVyZmFjZSI6ImV5SmpiMjF0WVc1a2MwbHVkR1Z5Wm1GalpTSTZleUprYVhOaFlteGxaRUpoWTJ0Q2RYUjBiMjRpT25SeWRXVXNJbTFsZEhKcFkzTWlPblJ5ZFdWOUxDSmhiR2NpT2lKSVV6STFOaUo5IiwiYWNjZXNzVG9rZW5IViI6ImV3b2dJQ0p6YVdkdVlYUjFjbVVpT2lBaU56Y3lNamc1TlRsaVlXTTVOVFF4Wm1Zd05qWmpNbU5oWXpJMk9URTBabUU0WldVMllqSTNZVEUzTXpoa09ERTJabU0xWXpFeU1XSmxOekEwTVRsa09UaGhNMkl4TnpZeU5XVmxOVEEyWkRFNFlUYzJOemc1WldRNFlUQTFaVEV3TjJZM1pHRTVNall6TlRWa1pqY3dZVFJtTldZME5UQXpPR1prTTJKalpEUWlMQW9nSUNKMGFXMWxjM1JoYlhBaU9pQXlNamsyTVRrNE9UZ3pDbjA9IiwiY2hvb3NlRm9ybUJ1dHRvbiI6ZmFsc2UsInNlYXJjaFlvdXJSb29tQnV0dG9uIjpmYWxzZSwiY3VzdG9tQ29uZmlnIjp7ImNhbkJFUmVkaXJlY3QiOm51bGwsImNhbkJFU2VhcmNoIjpudWxsLCJoYXNTdGFydGluZ1ByaWNlQ2F0ZWdvcnkiOm51bGwsImNhbkNob29zZVlvdXJSb29tIjpudWxsfX0=&devMode=false',
  '7380':
    'https://map.hotelverse.tech/chooseForm?config=eyJob3RlbElkIjoxMDUsImNsaWVudElkIjoiMzMiLCJwcm9kdWN0IjoiQ2hvb3NlWW91clJvb20iLCJjb21tYW5kc0ludGVyZmFjZSI6ImV5SmpiMjF0WVc1a2MwbHVkR1Z5Wm1GalpTSTZleUprYVhOaFlteGxaRUpoWTJ0Q2RYUjBiMjRpT25SeWRXVXNJbTFsZEhKcFkzTWlPblJ5ZFdWOUxDSmhiR2NpT2lKSVV6STFOaUo5IiwiYWNjZXNzVG9rZW5IViI6ImV3b2dJQ0p6YVdkdVlYUjFjbVVpT2lBaU56Y3lNamc1TlRsaVlXTTVOVFF4Wm1Zd05qWmpNbU5oWXpJMk9URTBabUU0WldVMllqSTNZVEUzTXpoa09ERTJabU0xWXpFeU1XSmxOekEwTVRsa09UaGhNMkl4TnpZeU5XVmxOVEEyWkRFNFlUYzJOemc1WldRNFlUQTFaVEV3TjJZM1pHRTVNall6TlRWa1pqY3dZVFJtTldZME5UQXpPR1prTTJKalpEUWlMQW9nSUNKMGFXMWxjM1JoYlhBaU9pQXlNamsyTVRrNE9UZ3pDbjA9IiwiY2hvb3NlRm9ybUJ1dHRvbiI6ZmFsc2UsInNlYXJjaFlvdXJSb29tQnV0dG9uIjpmYWxzZSwiY3VzdG9tQ29uZmlnIjp7ImNhbkJFUmVkaXJlY3QiOm51bGwsImNhbkJFU2VhcmNoIjpudWxsLCJoYXNTdGFydGluZ1ByaWNlQ2F0ZWdvcnkiOm51bGwsImNhbkNob29zZVlvdXJSb29tIjpudWxsfX0=&devMode=false',
  '36454':
    'https://map.hotelverse.tech/chooseForm?config=eyJob3RlbElkIjoxMDQsImNsaWVudElkIjoiMzMiLCJwcm9kdWN0IjoiQ2hvb3NlWW91clJvb20iLCJjb21tYW5kc0ludGVyZmFjZSI6ImV5SmpiMjF0WVc1a2MwbHVkR1Z5Wm1GalpTSTZleUprYVhOaFlteGxaRUpoWTJ0Q2RYUjBiMjRpT25SeWRXVXNJbTFsZEhKcFkzTWlPblJ5ZFdWOUxDSmhiR2NpT2lKSVV6STFOaUo5IiwiYWNjZXNzVG9rZW5IViI6ImV3b2dJQ0p6YVdkdVlYUjFjbVVpT2lBaU56Y3lNamc1TlRsaVlXTTVOVFF4Wm1Zd05qWmpNbU5oWXpJMk9URTBabUU0WldVMllqSTNZVEUzTXpoa09ERTJabU0xWXpFeU1XSmxOekEwTVRsa09UaGhNMkl4TnpZeU5XVmxOVEEyWkRFNFlUYzJOemc1WldRNFlUQTFaVEV3TjJZM1pHRTVNall6TlRWa1pqY3dZVFJtTldZME5UQXpPR1prTTJKalpEUWlMQW9nSUNKMGFXMWxjM1JoYlhBaU9pQXlNamsyTVRrNE9UZ3pDbjA9IiwiY2hvb3NlRm9ybUJ1dHRvbiI6ZmFsc2UsInNlYXJjaFlvdXJSb29tQnV0dG9uIjpmYWxzZSwiY3VzdG9tQ29uZmlnIjp7ImNhbkJFUmVkaXJlY3QiOm51bGwsImNhbkJFU2VhcmNoIjpudWxsLCJoYXNTdGFydGluZ1ByaWNlQ2F0ZWdvcnkiOm51bGwsImNhbkNob29zZVlvdXJSb29tIjpudWxsfX0=&devMode=false',
}

export function mapHotelVerse(hotelId: string): string | undefined {
  const isHotelVerseEnabled = isFeatureFlagEnabled(
    'FF_FEATURE_6460_HOTEL_VERSE',
  )
  if (!isHotelVerseEnabled) return undefined
  return hotelVerse[hotelId]
}
