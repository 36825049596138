import {
  ChangeEvent,
  ComponentProps,
  forwardRef,
  ForwardRefRenderFunction,
} from 'react'
import { useFormContext } from 'react-hook-form'
import { CheckboxField } from 'src/ui/components'

const FormCheckboxWithRef: ForwardRefRenderFunction<
  HTMLInputElement,
  Omit<Omit<ComponentProps<typeof CheckboxField>, 'checked'>, 'error'>
> = (props, ref) => {
  const { watch, getFieldState, formState, trigger } = useFormContext()
  const checked = watch(props.name)
  const error = getFieldState(props.name, formState).error

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    props.onChange?.(event)
    trigger(props.name)
  }

  return (
    <CheckboxField
      {...props}
      ref={ref}
      onChange={handleChange}
      checked={checked}
      error={error}
    >
      {props.children}
    </CheckboxField>
  )
}

export const FormCheckbox = forwardRef(FormCheckboxWithRef)
