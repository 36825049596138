import type { FocusEventHandler, FormEventHandler, ReactNode } from 'react'
import { forwardRef } from 'react'
import styles from './RadioButton.module.scss'
import { classNames } from 'src/ui/utils/classnames'

interface Props {
  className?: string
  inputClassName?: string
  children: ReactNode
  name: string
  value: string
  checked: boolean
  onChange?: FormEventHandler<HTMLInputElement>
  onBlur?: FocusEventHandler<HTMLInputElement>
}

export const RadioButton = forwardRef<HTMLInputElement, Props>(
  function RadioButton({ className, inputClassName, children, ...props }, ref) {
    return (
      <label className={classNames(styles.label, className)}>
        <input
          {...props}
          type="radio"
          ref={ref}
          className={classNames(styles.inputRadio, inputClassName)}
        />
        {children}
      </label>
    )
  },
)
