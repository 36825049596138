import {
  availability,
  hotel,
  preReservation,
  preReservationFromLogin,
  preReservationFromCouponChange,
  reservation,
  user,
  guests,
  paymentMethods,
  currentPaymentMethod,
  clickPhone,
  clickMyReservations,
  clickPersonalData,
  changeDates,
  changeGuests,
  changeCoupon,
  clickLogin,
  clickRegister,
  changeBoardBasis,
  genericError,
  availabilitySystemError,
  availabilityError,
  confirmReservation,
  beforePaymentRedirection,
  openModal,
  addExtra,
  modifyExtra,
  removeExtra,
  continueWithExtras,
  continueWithoutExtras,
  modifyExtraFromCart,
  invalidPromotionalCouponAvailableRooms,
  invalidPromotionalCouponChoosePayment,
  callMeNow,
  callMeBack,
} from 'analytics/links'
import { Analytics } from 'src/core/Shared/domain/Analytics'
import { paymentError } from 'analytics/links/actions/choosePayment/paymentError'
import { logout } from 'analytics/links/actions/shared/logout'
import { launchAnalytics } from 'src/core/Shared/infrastructure/analytics/launchAnalytics'

const ignoreErrors =
  (analyticFunction: (...args: any[]) => void) =>
  (...args: any[]) => {
    launchAnalytics(analyticFunction, args, 'link', null)
  }

export const analytics: Analytics = {
  requests: {
    availability: ignoreErrors(availability),
    hotel: ignoreErrors(hotel),
    preReservation: ignoreErrors(preReservation),
    preReservationFromLogin: ignoreErrors(preReservationFromLogin),
    preReservationFromCouponChange: ignoreErrors(
      preReservationFromCouponChange,
    ),
    reservation: ignoreErrors(reservation),
    user: ignoreErrors(user),
    guests: ignoreErrors(guests),
    paymentMethods: ignoreErrors(paymentMethods),
    currentPaymentMethod: ignoreErrors(currentPaymentMethod),
  },
  actions: {
    availableRooms: {
      changeBoardBasis: ignoreErrors(changeBoardBasis),
      invalidPromotionalCoupon: ignoreErrors(
        invalidPromotionalCouponAvailableRooms,
      ),
    },
    extras: {
      openModal: ignoreErrors(openModal),
      addExtra: ignoreErrors(addExtra),
      modifyExtra: ignoreErrors(modifyExtra),
      modifyExtraFromCart: ignoreErrors(modifyExtraFromCart),
      removeExtra: ignoreErrors(removeExtra),
      continueWithExtras: ignoreErrors(continueWithExtras),
      continueWithoutExtras: ignoreErrors(continueWithoutExtras),
    },
    header: {
      clickPhone: ignoreErrors(clickPhone),
      clickMyReservations: ignoreErrors(clickMyReservations),
      clickPersonalData: ignoreErrors(clickPersonalData),
    },
    choosePayment: {
      invalidPromotionalCoupon: ignoreErrors(
        invalidPromotionalCouponChoosePayment,
      ),
      confirmReservation: ignoreErrors(confirmReservation),
      beforePaymentRedirection: ignoreErrors(beforePaymentRedirection),
      paymentError: ignoreErrors(paymentError),
    },
    shared: {
      callMeNow: ignoreErrors(callMeNow),
      callMeBack: ignoreErrors(callMeBack),
      changeDates: ignoreErrors(changeDates),
      changeGuests: ignoreErrors(changeGuests),
      changeCoupon: ignoreErrors(changeCoupon),
      clickLogin: ignoreErrors(clickLogin),
      clickRegister: ignoreErrors(clickRegister),
      logout: ignoreErrors(logout),
    },
  },
  errors: {
    generic: ignoreErrors(genericError),
    availabilitySystem: ignoreErrors(availabilitySystemError),
    availability: ignoreErrors(availabilityError),
  },
}
