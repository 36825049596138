/**
 * Bloquea y desbloquea el scroll del body
 * El nivel de scroll se mantiene durante el bloqueo
 * Compatible con iOS
 */

/** Utilizamos un valor global en vez de un estado para que funcione cuando se instancia el hook en múltiples componentes en una misma página
 * p.ej: Carousel
 */
let scrollPosition = 0

export const useLockBodyScroll = () => {
  return {
    lockBodyScroll: () => {
      // Elimina el scroll del body (compatible iOS)
      scrollPosition = window.scrollY
      document.body.style.top = `-${scrollPosition}px`
      document.body.style.overflowY = 'scroll'
      document.body.style.position = 'fixed'
      document.body.style.width = '100%'
    },
    releaseBodyScroll: () => {
      // Reestablece el scroll del body
      document.body.style.removeProperty('position')
      document.body.style.removeProperty('top')
      document.body.style.removeProperty('overflow-y')
      document.body.style.removeProperty('width')
      window.scrollTo({
        top: scrollPosition,
        left: 0,
        behavior: 'instant',
      })
    },
  }
}
