import { i18n } from 'next-i18next'
import { AvailableLanguageConfig } from './types'
import { defaultLanguage } from 'i18n-config'
import availableLanguagesRaw from 'available-languages.json'

export const getApiAcceptLanguage = () => {
  const actualLanguageConfig = getCurrentLanguageConfig()

  return (
    actualLanguageConfig?.languageProperties?.apiLanguage ?? DEFAULT_LANGUAGE
  )
}

export function getCurrentLanguageConfig(): AvailableLanguageConfig {
  return findLanguageConfig(i18n?.language) || getDefaultLanguageConfig()
}

export function getDefaultLanguageConfig() {
  return getLanguageConfig(DEFAULT_LANGUAGE)
}

export function getLanguageConfig(cbeLanguage: string | undefined) {
  return findLanguageConfig(cbeLanguage) || defaultLanguageConfig
}

function findLanguageConfig(cbeLanguage: string | undefined) {
  if (!cbeLanguage) {
    return undefined
  }
  return availableLanguagesRaw.find(rawLng => {
    return (
      rawLng.languageProperties?.cbeLanguage.toLowerCase() ===
      cbeLanguage.toLowerCase()
    )
  })
}

export const DEFAULT_LANGUAGE = defaultLanguage

const defaultLanguageConfig: AvailableLanguageConfig = {
  languageProperties: {
    culture: 'en-GB',
    cmsLanguage: 'en_gb',
    crowdinLanguage: 'en-GB',
    cbeLanguage: 'en-GB',
    apiLanguage: 'en-GB',
    salesforceLanguage: 'en_GB',
    description: 'English - GB',
    isoCode: 'en',
    phoneFieldLanguage: 'gb',
    isRTL: false,
  },
  currencyProperties: {
    commaAsThousandsSeparator: true,
    currencySymbolBeforeNumber: false,
  },
}
