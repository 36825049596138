import { FC } from 'react'
import styles from './Footer.module.scss'
import { Button } from 'src/ui/components/atoms/Button'
import { Text } from 'src/ui/components/atoms/Text'
import { useTrans } from 'src/ui/hooks/useTrans'
import { Flex } from 'src/ui/styles/objects/Flex'
import { ButtonLink } from 'src/ui/components/atoms/ButtonLink'
import { Icon } from 'src/ui/components/atoms/Icon'
import ArrowIcon from 'src/ui/components/atoms/Icon/_icons/navigation/ArrowIcon'
import { useDatePicker } from '../DatePickerContext'
import { Tag } from 'src/ui/components'
import MoonIcon from 'src/ui/components/atoms/Icon/_icons/general/MoonIcon'
import {
  isDefined,
  isUndefined,
} from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'
import { useMedia } from 'src/ui/hooks/useMedia'
import { getDateWithYearAndWeekDay } from 'src/core/Shared/domain/Reservation.model'
import { LegendPriceRange } from '../PriceRange'
import { AlertToast } from 'src/ui/components/molecules/AlertToast'
import { Time } from 'src/core/Shared/infrastructure/Time'
import { useStepper } from 'src/ui/hooks/useStepper'
import { StepId } from 'src/core/Shared/infrastructure/stepperManager'
import { AlertInline } from 'src/ui/components/molecules/AlertInline'

interface Props {
  nights: number
  errorMessage: string | undefined
  position: string
  handleCloseNotification: () => void
}

export const Footer: FC<Props> = ({
  nights,
  errorMessage,
  handleCloseNotification,
}) => {
  const { trans } = useTrans([
    'common',
    'manage-reservation',
    'new-reservation',
  ])
  const { media } = useMedia()
  const { checkIn, checkOut } = useDatePicker()
  const { currentStep } = useStepper()

  const isMobileOrTablet = media === 'mobile' || media === 'tablet'
  const { checkInDate: checkInFormatted, checkOutDate: checkOutFormatted } =
    getDateWithYearAndWeekDay(checkIn, checkOut)

  const notificationType =
    errorMessage === trans('common:dates-modal_no-dispo-error')
      ? 'error'
      : 'info'

  const showAlertReturnStep1 = currentStep !== StepId.AVAILABLE_ROOMS

  return (
    <Flex direction="column" className={styles.calendarFooter}>
      <div className={styles.legendPriceRangeWrapper}>
        {isDefined(errorMessage) && (
          <AlertToast
            description={errorMessage}
            type={notificationType}
            isOpen={true}
            closeAutomaticallyAfter={Time.seconds(3)}
            onClose={handleCloseNotification}
            className={styles.footerAlert}
          />
        )}
        <LegendPriceRange />
        {showAlertReturnStep1 && (
          <AlertInline
            type="info"
            text={trans('new-reservation:dates-modal_alert-return-step-1')}
            className={styles.alertReturnStep1}
          />
        )}
      </div>

      <Flex direction="column" gap="m" className={styles.calendarFooterContent}>
        <Flex
          justifyContent="space-between"
          alignItems="center"
          className={styles.datesContainer}
        >
          <div className={styles.footerDatesWrapper}>
            <div className={styles.checkInWrapper}>
              <Text as="p" fontStyle="xs-500" uppercase color="mid">
                {trans('check-in_title')}
              </Text>
              <Text
                as="p"
                fontStyle="s-500"
                capitalize
                className={
                  isUndefined(checkInFormatted) ? styles.selectTitle : undefined
                }
              >
                {checkInFormatted ?? trans('select-title')}
              </Text>
            </div>

            <Icon icon={ArrowIcon} size="l" rotate={180} />

            <div className={styles.checkOutWrapper}>
              <Text as="p" fontStyle="xs-500" uppercase color="mid">
                {trans('check-out_title')}
              </Text>
              <Text
                as="p"
                fontStyle="s-500"
                capitalize
                className={
                  isDefined(checkInFormatted) && isUndefined(checkOutFormatted)
                    ? styles.selectTitle
                    : undefined
                }
              >
                {checkOutFormatted ?? trans('select-title')}
              </Text>
            </div>
          </div>

          {nights > 0 && (
            <Tag className={styles.nightsTag}>
              <Flex gap="xxs">
                <Text
                  as="p"
                  fontStyle="xs-500"
                  className={styles.nightsText}
                  ariaLabel={trans('number-of-nights', { count: nights })}
                >
                  {isMobileOrTablet
                    ? nights
                    : trans('number-of-nights', { count: nights })}
                </Text>

                <Icon icon={MoonIcon} size="s" color="support-info-dark" />
              </Flex>
            </Tag>
          )}
        </Flex>

        <Flex
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          className={styles.footerButtons}
        >
          <ButtonLink className={styles.resetButton} type="reset">
            {trans('reset-title')}
          </ButtonLink>
          <Button
            size="large"
            type="submit"
            className={styles.confirmButton}
            data-testid="datesSubmitButton"
          >
            {isDefined(checkIn) && isDefined(checkOut)
              ? trans('confirm-dates')
              : trans('choose-dates')}
          </Button>
        </Flex>
      </Flex>
    </Flex>
  )
}
