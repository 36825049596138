import { FC, ReactNode } from 'react'
import styles from './Modal.module.scss'
import { classNames } from 'src/ui/utils/classnames'
import { Icon } from 'src/ui/components/atoms/Icon/Icon'
import { useEscapeOnClose } from 'src/ui/hooks/useEscapeOnClose'
import { useTrans } from 'src/ui/hooks/useTrans'
import { isDefined } from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'
import { useModal } from 'src/ui/hooks/useModal'
import { useLockBodyScroll } from 'src/ui/hooks/useLockBodyScroll'
import { CloseIcon } from 'src/ui/components/atoms/Icon/_icons/interaction/CloseIcon'
import { ModalContent } from 'src/ui/components/molecules/Modal/ModalContent'
import ReactModal from 'react-modal'

type ModalSize = 's' | 'm' | 'l' | 'xl'
type MobileStyle = 'bottom' | 'fullscreen' | 'centered'

interface Props {
  children: ReactNode
  ariaLabel?: string
  className?: string
  size: ModalSize
  mobileStyle?: MobileStyle
  type: 'transactional' | 'passive'
  closable?: boolean
  onCloseIconClick?: () => void
  onClose?: () => void
  'data-testid'?: string
  'data-quantum-modal'?: string
  // Ajusta los estilos de la cabecera de la modal para que no ocupen espacio
  withCustomHeader?: boolean
  /** Ajusta estilos para que la botonera del pie incluida en el propio
   * contenido pueda anclarse a la parte inferior sin margenes extra */
  withButtonsFooter?: boolean
  hasToLockBodyScroll?: boolean
}

export const Modal: FC<Props> = ({
  children,
  ariaLabel,
  className,
  size,
  mobileStyle = 'bottom',
  type,
  closable = true,
  onCloseIconClick,
  onClose,
  'data-testid': testId,
  'data-quantum-modal': dataQuantumModal,
  withCustomHeader = false,
  withButtonsFooter = false,
  hasToLockBodyScroll = true,
}) => {
  const { trans } = useTrans(['common'])
  const { visibleModal, hideModal, removeModal } = useModal()
  const { lockBodyScroll, releaseBodyScroll } = useLockBodyScroll()

  const close = () => {
    hideModal()
    onClose?.()
  }

  useEscapeOnClose({ onClose: close })

  // It is important for users of screenreaders that other page content be hidden while the modal is open
  // https://reactcommunity.org/react-modal/accessibility/
  if (
    process.env.NODE_ENV !== 'test' &&
    document.getElementById('__next') !== null
  ) {
    ReactModal.setAppElement('#__next')
  }

  const isMobileFullscreen = mobileStyle === 'fullscreen'
  const isMobileCentered = mobileStyle === 'centered'

  return (
    <ReactModal
      isOpen={visibleModal}
      style={{
        overlay: {
          position: 'unset',
          inset: 'unset',
          backgroundColor: 'transparent',
        },
        content: {
          padding: 'unset',
          border: 'unset',

          // Posicionamiento de la modal frente al resto
          zIndex: '10000',

          // en iOS 14, el color de fondo que pone el componente hace que se
          // vea mal, sin color de fondo perfecto
          backgroundColor: 'unset',
        },
      }}
      onAfterOpen={() => {
        hasToLockBodyScroll && lockBodyScroll()
      }}
      onAfterClose={() => {
        hasToLockBodyScroll && releaseBodyScroll()
        removeModal()
      }}
      onRequestClose={() => {
        close()

        if (isDefined(onCloseIconClick)) {
          onCloseIconClick()
        }
      }}
    >
      <div
        className={classNames(
          styles.container,
          isMobileFullscreen && styles.container_isMobileFullscreen,
        )}
        role="dialog"
        aria-label={ariaLabel}
        data-testid={testId}
        data-quantum-modal={dataQuantumModal}
      >
        <div
          className={classNames(
            styles.wrapper,
            isMobileFullscreen && styles.wrapper_isMobileFullscreen,
          )}
        >
          <div
            className={classNames(
              styles.modal,
              styles[`modal_${size}`],
              isMobileFullscreen && styles.modal_isMobileFullscreen,
              isMobileCentered && styles.modal_isMobileCentered,
              isDefined(className) && className,
            )}
            onClick={e => e.stopPropagation()}
          >
            <div
              className={classNames(
                withCustomHeader && styles.customModalToolsWrapper,
              )}
            >
              <div
                className={classNames(
                  styles.modalTools,
                  withCustomHeader && styles.customModalTools,
                )}
              >
                {closable && (
                  <button
                    className={styles.exitButton}
                    onClick={() => {
                      close()

                      if (isDefined(onCloseIconClick)) {
                        onCloseIconClick()
                      }
                    }}
                    aria-label={trans('modal_close-button_aria-label')}
                  >
                    <Icon size="l" icon={CloseIcon} />
                  </button>
                )}
              </div>
            </div>
            <ModalContent
              isMobileFullscreen={isMobileFullscreen}
              size={size}
              type={type}
              withButtonsFooter={withButtonsFooter}
            >
              {children}
            </ModalContent>
          </div>
        </div>
      </div>
    </ReactModal>
  )
}
