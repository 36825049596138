import { useEffect, useState } from 'react'
import { isFeatureFlagEnabled } from 'src/core/Shared/infrastructure/featureFlags'
import { useStepper } from './useStepper'
import { CallMeBackType } from 'src/ui/views/_components/CallMe'

export const useCallMeBack = () => {
  const { currentStep } = useStepper()
  const hasToShowCallMeBackWidget = isFeatureFlagEnabled(
    'FF_FEATURE_5396_CALL_ME_BACK',
  )
  const [isWidgetVisible, setWidgetVisibile] = useState(false)
  const [utagObject, setUtagObject] = useState({})
  const [modalType, setModalType] = useState<CallMeBackType>('default')

  useEffect(() => {
    if (!hasToShowCallMeBackWidget) {
      return () => {}
    }
    window.cxoOpenCallMeBack = (
      cmbAnalyticObject: object = {},
      modalType: CallMeBackType = 'default',
    ) => {
      setWidgetVisibile(true)
      setUtagObject(cmbAnalyticObject)
      setModalType(modalType)
    }

    return () => {
      delete window.cxoOpenCallMeBack
    }
  }, [hasToShowCallMeBackWidget])

  useEffect(() => {
    setWidgetVisibile(false)
  }, [currentStep])

  return { isWidgetVisible, utagObject, modalType }
}
