import { useState } from 'react'
import { Text } from 'src/ui/components'
import { Icon } from 'src/ui/components/atoms/Icon'
import CloseIcon from 'src/ui/components/atoms/Icon/_icons/interaction/CloseIcon'
import PhoneIcon from 'src/ui/components/atoms/Icon/_icons/interaction/PhoneIcon'
import { useMedia } from 'src/ui/hooks/useMedia'
import { useModal } from 'src/ui/hooks/useModal'
import { useTrans } from 'src/ui/hooks/useTrans'
import { Media } from 'src/ui/styles/objects/Media'
import { CallMeBackModal } from 'src/ui/views/_components/CallMe/CallMeBackModal'
import styles from './CallMeBackWidget.module.scss'
import { CallMeBackType } from '../index'
import { container } from 'src/core/Shared/_di'
import { CallMeSuccessModal } from '../CallMeSuccessModal'

export type CallMeBackWidgetProps = {
  type: CallMeBackType
  analytic: object
}
export const CallMeBackWidget = ({ type, analytic }: CallMeBackWidgetProps) => {
  const { media } = useMedia()
  const { trans } = useTrans(['common'])
  const [isMessageVisible, setIsMessageVisible] = useState(false)

  const { showModal: showCallMeBackModal, removeModal: removeCallMeBackModal } =
    useModal(CallMeBackModal)
  const { showModal: showCallMeSuccessModal } = useModal(CallMeSuccessModal)

  const handleWidgetClick = () => {
    if (media === 'tablet' || media === 'mobile') {
      setIsMessageVisible(!isMessageVisible)
    }
  }

  const handleCallMeBackSubmit = (callMeBackData: object) => {
    container.resolve('analytics').actions.shared.callMeBack(callMeBackData)
    removeCallMeBackModal()
    return showCallMeSuccessModal({ type })
  }

  const handleLinkClick = () => {
    return showCallMeBackModal({
      type,
      analytic,
      onSubmit: handleCallMeBackSubmit,
    })
  }

  const renderMessageButton = () => {
    return (
      <button onClick={handleLinkClick}>
        <Text className={styles.textLink} fontStyle="m-700">
          {trans('call-me-back-widget_message')}
        </Text>
      </button>
    )
  }

  return (
    <div
      className={styles.container}
      aria-label={trans('call-me-back-widget_label')}
    >
      <button onClick={handleWidgetClick} className={styles.clickableZone}>
        <div className={styles.imageContainer} />
        <div className={styles.iconContainer}>
          <Icon icon={PhoneIcon} size="m" color="bg-white" />
        </div>
      </button>

      <Media laptop desktop>
        <div className={styles.messageContainer}>{renderMessageButton()}</div>
      </Media>

      {isMessageVisible && (
        <div className={styles.messageContainer}>
          {renderMessageButton()}
          <button
            onClick={() => {
              setIsMessageVisible(false)
            }}
          >
            <Icon size="m" icon={CloseIcon} />
          </button>
        </div>
      )}
    </div>
  )
}
