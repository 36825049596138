import { FC, ReactNode } from 'react'
import { Divider, Text } from 'src/ui/components'
import styles from './Advantages.module.scss'
import { Icon } from 'src/ui/components/atoms/Icon'
import CheckIcon from 'src/ui/components/atoms/Icon/_icons/system/CheckIcon'
import MinusIcon from 'src/ui/components/atoms/Icon/_icons/interaction/MinusIcon'
import { IconSize } from 'src/ui/styles/settings'
import { classNames } from 'src/ui/utils/classnames'
import { useTrans } from 'src/ui/hooks/useTrans'
import { BenefitType, Benefit as BenefitModel } from './BenefitType'
import { Flex } from 'src/ui/styles/objects/Flex'
import { Tooltip } from 'src/ui/components/molecules/Tooltip'
import InfoIcon from 'src/ui/components/atoms/Icon/_icons/interaction/InfoIcon'
import { isDefined } from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'
import { Translate } from 'src/ui/i18n/Translate'

type UserType = 'essential' | 'special' | 'unique'

interface Props {
  benefits: BenefitType[]
  type: UserType
}

export const UserLevelTab: FC<Props> = ({ benefits, type }) => {
  const { trans } = useTrans(['myb-lite-landing'])
  return (
    <div className={classNames(styles.advantageTab, styles[`${type}Tab`])}>
      <header>
        <Text
          fontStyle="l-700"
          as="h2"
          color={type === 'unique' ? 'light' : 'dark'}
        >
          {trans(`lite_landing-advantages-header_title_${type}`)}
        </Text>
        {type !== 'unique' && (
          <Text fontStyle="m-300">
            {trans(`lite_landing-advantages-header_description_${type}`)}
          </Text>
        )}
      </header>
      <section>
        {benefits?.map((benefit, index) => (
          <Benefit key={index} {...benefit} userType={type} />
        ))}
      </section>
    </div>
  )
}

function Benefit({
  userType,
  icon,
  title,
  benefits,
}: {
  userType: UserType
  icon: (size: IconSize) => ReactNode
  title: string
  benefits: BenefitModel[]
  key: number
}) {
  const { trans } = useTrans(['myb-lite-landing'])
  return (
    <>
      <h3 className={styles.benefitTitle}>
        {icon('l')}
        <Translate
          i18nKey={`myb-lite-landing:${title}`}
          components={{
            bold: <Text fontStyle="m-700" />,
            regular: <Text fontStyle="m-300" />,
          }}
        />
      </h3>
      <Divider dir="horizontal" className={styles.divider} />
      <ul className={styles.benefits}>
        {benefits.map((benefit, index) => (
          <div key={index}>
            <li>
              <Flex alignItems="center" justifyContent="space-between" gap="xs">
                <Text fontStyle="s-500">{trans(benefit.description)}</Text>
                {isDefined(benefit.tooltip) && (
                  <Tooltip
                    tooltipWrapperClassName={styles.tooltipContent}
                    triggerElement={<Icon size="m" icon={InfoIcon} />}
                    tooltipContent={trans(benefit.tooltip)}
                  />
                )}
              </Flex>
              <BenefitValue value={benefit.value} userType={userType} />
            </li>
            {index < benefits.length - 1 && <Divider dir="horizontal" />}
          </div>
        ))}
      </ul>
    </>
  )
}

const BenefitValue: FC<{
  value: string
  userType: UserType
}> = ({ value }) => {
  const { trans } = useTrans(['myb-lite-landing'])

  if (value === 'check') {
    return <Icon icon={CheckIcon} size="l" color="fill-ocean-100" />
  }

  if (value === 'line') {
    return <Icon icon={MinusIcon} size="l" color="icon-mid" />
  }

  return (
    <Text className="self-center" fontStyle="m-300" color="ocean-100">
      {trans(value)}
    </Text>
  )
}
