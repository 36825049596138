import type {
  FieldValues,
  FieldPathByValue,
  RegisterOptions,
  PathValue,
} from 'react-hook-form'
import { ComponentProps } from 'react'
import { PhoneField } from 'src/ui/components'
import { useFormContext, useWatch } from 'react-hook-form'

interface Props<TFieldValues extends FieldValues>
  extends Omit<ComponentProps<typeof PhoneField>, 'name'> {
  name: FieldPathByValue<TFieldValues, string>
  field: RegisterOptions<TFieldValues>
}

export function FormPhoneField<TFieldValues extends FieldValues>({
  name,
  field,
  onChange,
  ...restProps
}: Props<TFieldValues>) {
  const { register, getFieldState, formState, setValue } =
    useFormContext<TFieldValues>()
  const value = useWatch<TFieldValues>({ name }) ?? ''
  const error = getFieldState(name, formState).error

  return (
    <PhoneField
      {...register(name, field)}
      {...restProps}
      onChange={handleChange}
      value={value}
      error={error}
    />
  )

  function handleChange(phoneNumber: string) {
    setValue(name, phoneNumber as PathValue<TFieldValues, typeof name>)
    onChange?.(phoneNumber)
  }
}
