import { NightlyRate } from 'src/core/Shared/domain/NightlyRate.model'
import {
  CancellationPolicy,
  getDefaultCancellation,
  PoliciesDetails,
} from 'src/core/Shared/domain/Policies.model'
import { CurrencyISOCode, Price } from 'src/core/Shared/domain/Price.model'
import {
  head,
  isDefined,
  isEmpty,
  isUndefined,
} from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'
import { PaidReservationStatus } from 'src/core/Payment/domain/Payment.model'
import {
  AppliedExtraPerGuest,
  AppliedExtraPerUnit,
  NotAppliedExtra,
} from 'src/core/Shared/domain/Extra.model'
import { User } from 'src/core/User/domain/User'
import { ExtendedPriceDTO } from 'src/core/Shared/infrastructure/dto/Price.dto'
import { ValidatedCoupon } from 'src/core/Reservation/domain/ValidatedCoupon'
import { isAppliedExtraPerUnitOrPerGuest } from 'src/core/Shared/domain/Extra.model'
import {
  isLevelCoupon,
  isPromotionalCoupon,
  LevelCoupon,
  PromotionalCoupon,
  ReservationCoupon,
} from '../../Reservation/domain/ReservationCoupon'
import { datesManager } from 'src/core/Shared/infrastructure/datesManager'
import { CouponType } from 'src/core/Shared/domain/CouponType'

export type ReservationStatus =
  | 'Pending'
  | 'Committed'
  | 'Cancelled'
  | 'Ignored'

export type NonAppliedMessageType = 'not-compatible' | 'not-applied' | undefined

interface Occupancy {
  adults: number
  children: number
  childrenAges?: number[]
}

export interface Reservation {
  id: string
  itineraryNumber: string
  status: ReservationStatus
  hotel: {
    id: string
    name: string
  }
  checkIn: Date
  checkOut: Date
  roomsNumber: number
  occupancy: Occupancy
  prices: {
    total: TotalPrice
    tax: {
      original: Price
    }
    guarantee: {
      deposit: Price
      pending: Price
    }
    originalDeposit: Price
  }
  isGuestIdCardRequired: boolean
  isGuestMember: boolean
  guaranteedByName: boolean
  roomStays: ReservationRoomStay[]
  summary: ReservationSummary
  rateCurrencies: CurrencyISOCode[]
  marketCampaign: string | undefined
  comment?: string
}

export interface TotalPrice {
  original: Price
  converted: Price
  isSameCurrency: boolean
}

export enum IdCardType {
  NID = 'NID',
  PASSPORT = 'PASSPORT',
}

export interface IDCard {
  type: IdCardType
  id: string
}

export interface ReservedRoomAndRate {
  roomId: string
  rateId: string
}

export interface SelectedRoom {
  roomId: string
  mealplanId: string
  id: string
}

export interface SelectedMealplan {
  id: string
  name: string
}

export interface RoomMetadata {
  roomName: string
  mealplan: SelectedMealplan
  rateId: string
}

export interface ReservationRoomStay extends PoliciesDetails {
  id: string
  confirmationNumber: string
  status: ReservationRoomStayStatus
  roomCount: number
  startDate: Date
  endDate: Date
  hotel: {
    id: string
    name: string
  }
  extras: Array<AppliedExtraPerUnit | AppliedExtraPerGuest | NotAppliedExtra>
  room: {
    id: string
    name: string
  }
  occupancy: {
    adults: number
    children: number
    childrenAges: number[]
  }
  rate: RoomStayRate
  guest: ReservationGuest
  groupCode?: string
  coupons?: ReservationCoupon[]
  // originalTotalPrice se propagan sin mapear porque lo único que hacemos con ellos es recogerlos y mandarlos de vuelta en otras peticiones. Forman parte de un fix de un issue de Sabre que se solucionara a medio plazo y se acabarán borrando.
  originalTotalPrice?: {
    base: ExtendedPriceDTO
    total: ExtendedPriceDTO
  }
}

export interface ViewingAccelerator {
  roomTypeId: string
  count: number
}

export interface ReservationSummary {
  netDiscount?: Price
  grossDiscount?: Price
}

export interface RoomStayRate {
  id: string
  mealPlan: { id: string; name: MealPlanType }
  averageNightly: { netPrice: Price; grossPrice: Price }
  nightly: NightlyRate[]
  total: {
    taxes: { percentage: string; price: Price }
    netPrice: Price
    grossPrice: Price
    discount: number
  }
  nonConvertedTotal: {
    netPrice: Price
    grossPrice: Price
  }
  isMember: boolean
}

export type ReservationGuaranteeTimeline =
  | 'before-reservation'
  | 'after-reservation'

export enum MealPlanType {
  AllInclusive = 'All inclusive',
  BedAndBreakfast = 'Bed and Breakfast',
  HalfBoard = 'Half Board',
  FullBoard = 'Full Board',
  AllInclusivePremium = 'All inclusive Premium',
  AllInclusivePlus = 'All inclusive Plus',
  AccommodationOnly = 'Accommodation only',
}

export interface Address {
  city?: string
  street?: string
  state?: string
  postalCode?: string
}

export interface ReservationGuest {
  name: string
  surname: string
  email: string
  mainGuest?: boolean
  country?: string
  acceptPolicy?: boolean
  wantsToRegister?: boolean
  address?: Address
}

export interface ReservationGuestAdd {
  name: string
  surname: string
  email: string
  country: string | undefined
  mainGuest?: boolean
  acceptPolicy?: boolean
  wantsToRegister?: boolean
}

export type RatePairSelectedType = 'base' | 'related'

export interface RatePair {
  selected: RatePairSelectedType
  base: {
    id: string
    isMember: boolean
  }
  related?: {
    id: string
    isMember: boolean
  }
  convertedDifference?: Price
}

export type ReservationRoomStayStatus =
  | 'Pending'
  | 'Committed'
  | 'Cancelled'
  | 'Ignored'

export type SelectedReservationRateType = 'mixed' | 'allMember' | 'allStandard'

export interface ReservationSavings {
  savings: {
    price: Price
    applies: boolean
  }
  possibleSavings: {
    price: Price
    applies: boolean
  }
}

export interface PaidReservation {
  reservation: Reservation
  status: PaidReservationStatus | undefined
}

export interface CouponMetadata {
  coupon: ValidatedCoupon
  numberOfRooms: number
  isMoreThanOneRoomStay: boolean
  listOfRoomsNames: string
  nonAppliedMessageType: NonAppliedMessageType
}

export type HowCouponsApply = 'none' | 'every' | 'some'

export const getActiveRooms = (reservation: Reservation) =>
  reservation.roomStays.filter(roomStay => roomStay.status !== 'Cancelled')

export const isReservationSingleRoom = (reservation: Reservation) =>
  reservation.roomStays.length === 1

export const getSelectedRatesType = (
  ratePairs: RatePair[],
): SelectedReservationRateType => {
  const everySelectedIsBaseAndMember = ratePairs.every(
    ratePair => ratePair.selected === 'base' && ratePair.base.isMember,
  )
  if (everySelectedIsBaseAndMember) {
    return 'allMember'
  }

  const everySelectedIsStandard = ratePairs.every(
    ratePair => !ratePair[ratePair.selected]?.isMember,
  )
  if (everySelectedIsStandard) {
    return 'allStandard'
  }

  return 'mixed'
}

export const getSelectedRatesSaving = (
  ratePairs: RatePair[],
): ReservationSavings => {
  const savings = ratePairs.reduce(
    (acc, ratePair) => {
      if (ratePair.convertedDifference) {
        return {
          currency: ratePair.convertedDifference.currency,
          saving:
            ratePair.selected === 'base'
              ? acc.saving + ratePair.convertedDifference.value
              : acc.saving,
          possibleSaving:
            ratePair.selected === 'related'
              ? acc.possibleSaving + ratePair.convertedDifference.value
              : acc.possibleSaving,
        }
      }
      return acc
    },
    { currency: '', saving: 0, possibleSaving: 0 },
  )

  return {
    savings: {
      price: { currency: savings.currency, value: savings.saving } as Price,
      applies: savings.saving > 0,
    },
    possibleSavings: {
      price: {
        currency: savings.currency,
        value: savings.possibleSaving,
      } as Price,
      applies: savings.possibleSaving > 0,
    },
  }
}

export const transformToMemberRate = (
  ratePairs: RatePair[],
  reservation: Reservation,
) => {
  const updatedRoomStays = reservation.roomStays.map(roomStay => {
    const ratePair = ratePairs.find(
      ratePair => ratePair.related?.id === roomStay.rate.id,
    )
    if (isDefined(ratePair)) {
      return {
        ...roomStay,
        rate: {
          ...roomStay.rate,
          id: ratePair.base.id,
          isMember: true,
        },
      }
    }
    return roomStay
  })

  return {
    ...reservation,
    roomStays: updatedRoomStays,
  }
}

export interface ReservationCriteriaRoomStay {
  roomCount: number
  startDate: string
  endDate: string
  roomTypeId: string
  ratePlanId: string
  coupon?: string
  groupCode?: string
  occupancy: {
    adults: number
    children: number
    childrenAges: number[] | null
  }
  extras: Array<{
    id: string
    quantity?: number
    adultsQuantity?: number
    childrenQuantity?: number
  }>
  guest: ReservationGuest | undefined
  originalTotalPrice?: {
    base: ExtendedPriceDTO
    total: ExtendedPriceDTO
  }
}

export interface ReservationCriteria {
  hotelId: string
  marketCampaign: string | undefined
  roomStays: ReservationCriteriaRoomStay[]
  comment?: string
}

export const getPromotionalCouponName = (roomStay: ReservationRoomStay) => {
  const appliedRoomStayCoupon = roomStay?.coupons?.find(coupon =>
    isPromotionalCoupon(coupon),
  ) as PromotionalCoupon
  return appliedRoomStayCoupon?.name
}

export const appliesPromotionalCouponInReservation = (
  reservation: Reservation,
  coupon?: string,
) => {
  // ## TODO: Por ahora gestiona que aplica en cupones promocionales. Comparar cosas diferentes en función del tipo de cupón
  const promotionalCoupon = getPromotionalCouponInReservation(
    reservation.roomStays,
  )
  const hasSameName =
    promotionalCoupon?.value?.toLowerCase() === coupon?.toLowerCase()

  return !!promotionalCoupon?.applies && hasSameName
}

export const hasRoomStayCouponApplied = (roomStay: ReservationRoomStay) =>
  isDefined(roomStay.coupons) && roomStay.coupons.some(coupon => coupon.applies)

const getGroupCouponInReservation = (
  roomStays: Reservation['roomStays'],
): ValidatedCoupon | undefined => {
  const groupCouponRoomStay = roomStays.find(
    roomStay => isDefined(roomStay.groupCode) && !isEmpty(roomStay.groupCode),
  )

  if (
    isDefined(groupCouponRoomStay) &&
    isDefined(groupCouponRoomStay.groupCode)
  ) {
    return {
      applies: true,
      type: 'group',
      value: groupCouponRoomStay.groupCode,
    }
  }

  return undefined
}

export const howPromotionalCouponAppliesInReservation = (
  roomStays: Reservation['roomStays'],
  promotionalCoupon: string,
): HowCouponsApply => {
  if (roomStays.every(isAppliedPromotionalCoupon)) {
    return 'every'
  }

  if (roomStays.some(isAppliedPromotionalCoupon)) {
    return 'some'
  }

  return 'none'

  function isAppliedPromotionalCoupon(roomStay: ReservationRoomStay) {
    return roomStay.coupons?.some(
      coupon =>
        isPromotionalCoupon(coupon) &&
        coupon.name === promotionalCoupon &&
        coupon.applies,
    )
  }
}

export const getPromotionalCouponInReservation = (
  roomStays: Reservation['roomStays'],
): ValidatedCoupon | undefined => {
  if (isEmpty(roomStays)) {
    return undefined
  }
  const appliedRoomStay = roomStays.find(roomStay =>
    roomStay.coupons?.some(
      coupon => coupon.applies && isPromotionalCoupon(coupon),
    ),
  )

  const appliedRoomStayCouponName = isDefined(appliedRoomStay)
    ? getPromotionalCouponName(appliedRoomStay)
    : undefined

  if (isDefined(appliedRoomStayCouponName)) {
    return {
      applies: true,
      type: 'promotional',
      value: appliedRoomStayCouponName,
    }
  }

  const firstRoomStay = head(roomStays)
  const firstRoomStayCoupon = firstRoomStay?.coupons?.find(coupon =>
    isPromotionalCoupon(coupon),
  ) as PromotionalCoupon
  if (isDefined(firstRoomStayCoupon?.name)) {
    return {
      applies: false,
      type: 'promotional',
      value: firstRoomStayCoupon.name,
    }
  }

  return undefined
}

const getLevelCouponInReservation = (
  roomStays: Reservation['roomStays'],
  user?: User,
): ValidatedCoupon | undefined => {
  const hasLevelCouponApplied = roomStays.some(roomStay =>
    roomStay.coupons?.some(coupon => isLevelCoupon(coupon) && coupon.applies),
  )
  const hasLevelCouponDiscount = roomStays.some(roomStay =>
    roomStay.coupons?.some(
      coupon => isLevelCoupon(coupon) && coupon.levelDiscount,
    ),
  )

  if (!hasLevelCouponDiscount) {
    return undefined
  }

  return {
    applies: hasLevelCouponApplied,
    type: 'myBarcelo',
    value: user?.level.toString() ?? '',
  }
}

const getAllCouponsInReservation = (
  roomStays: Reservation['roomStays'] | undefined,
  user?: User,
): ValidatedCoupon[] | undefined => {
  if (isUndefined(roomStays)) {
    return undefined
  }
  const groupCoupon = getGroupCouponInReservation(roomStays)

  if (isDefined(groupCoupon)) {
    return [groupCoupon]
  }

  return [
    getPromotionalCouponInReservation(roomStays),
    getLevelCouponInReservation(roomStays, user),
  ].filter(isDefined)
}

/**
 * Obtiene información de los cupones aplicados a todas las habitaciones.
 * Con que una habitación ya aplique un tipo de cupón, recibiremos que dicho
 * cupón aplica en la reserva, aunque haya otras habitaciones donde no aplique
 * dicho cupón.
 */
export const getAllCouponMetadata = (
  roomStays: Reservation['roomStays'] | undefined,
  user?: User,
): CouponMetadata[] | undefined => {
  const coupons = getAllCouponsInReservation(roomStays, user)
  const isMoreThanOneRoomStay = isDefined(roomStays) && roomStays.length > 1
  const nonAppliedMessageType: NonAppliedMessageType =
    isDefined(coupons) && coupons.some(coupon => !coupon.applies)
      ? coupons.length > 1
        ? 'not-compatible'
        : 'not-applied'
      : undefined
  const allCouponsWithRooms = coupons?.map(coupon => {
    const numberOfRooms = numberOfRoomsForACoupon(roomStays, user, coupon)
    const listOfRoomsNames = listOfRoomNamesForACoupon(roomStays, user, coupon)

    return {
      coupon,
      numberOfRooms,
      isMoreThanOneRoomStay,
      listOfRoomsNames,
      nonAppliedMessageType,
    }
  })
  return isEmpty(allCouponsWithRooms) ? undefined : allCouponsWithRooms
}

/**
 * Obtiene información de los cupones aplicados a todas las habitaciones y de
 * cualquier tipo de cupón. Si un cupón sólo aplica a una de las habitaciones y
 * a otras no, se dará como cupón aplicado en la reserva.
 */
export const getAppliedCouponMetadata = (
  roomStays: Reservation['roomStays'] | undefined,
  user?: User,
): CouponMetadata[] | undefined => {
  const couponsMetadata = getAllCouponMetadata(roomStays, user)
  const appliedCouponsMetadata = couponsMetadata?.filter(
    couponMetadata => couponMetadata.coupon.applies,
  )

  return isEmpty(appliedCouponsMetadata) ? undefined : appliedCouponsMetadata
}

const filterRoomStaysByCouponOrUser = (
  roomStays: Reservation['roomStays'] | undefined,
  user?: User,
  coupon?: ValidatedCoupon,
): Reservation['roomStays'] => {
  return (
    roomStays?.filter(roomStay => {
      const someApplies = roomStay.coupons?.some(coupon => coupon?.applies)
      if (!someApplies) {
        return false
      }

      const promotionalCoupon = roomStay.coupons?.find(
        coupon => coupon?.applies && isPromotionalCoupon(coupon),
      ) as PromotionalCoupon | undefined

      if (isDefined(user)) {
        return [user?.level.toString(), promotionalCoupon?.name].includes(
          coupon?.value,
        )
      }

      const levelCoupon = roomStay.coupons?.find(
        coupon => coupon?.applies && isLevelCoupon(coupon),
      ) as LevelCoupon | undefined

      return (
        [promotionalCoupon?.name].includes(coupon?.value) ||
        (isDefined(levelCoupon) && isEmpty(coupon?.value))
      )
    }) ?? []
  )
}

const numberOfRoomsForACoupon = (
  roomStays: Reservation['roomStays'] | undefined,
  user?: User,
  coupon?: ValidatedCoupon,
): number => {
  return filterRoomStaysByCouponOrUser(roomStays, user, coupon).length
}

const listOfRoomNamesForACoupon = (
  roomStays: Reservation['roomStays'] | undefined,
  user?: User,
  coupon?: ValidatedCoupon,
): string => {
  return filterRoomStaysByCouponOrUser(roomStays, user, coupon)
    .map(roomStay => roomStay.room.name)
    .join(', ')
}

export const getDefaultReservationCancellation = (
  currency: CurrencyISOCode,
): CancellationPolicy => ({
  ...getDefaultCancellation(currency),
})

export const getAppliedExtrasInRoomStay = (roomStay: ReservationRoomStay) => {
  const appliedExtras = roomStay.extras.filter(isAppliedExtraPerUnitOrPerGuest)
  return appliedExtras
}

export const hasAppliedExtrasInRoomStay = (roomStay: ReservationRoomStay) => {
  return getAppliedExtrasInRoomStay(roomStay).length > 0
}

export const someExtrasNotApplied = (roomStays: ReservationRoomStay[]) => {
  return roomStays.some(roomStay =>
    roomStay.extras.some(extra => !extra.applied),
  )
}

export const isAppliedExtraPerUnit = (
  object: AppliedExtraPerUnit | AppliedExtraPerGuest,
): object is AppliedExtraPerUnit => 'quantity' in object

export const isAppliedExtraPerGuest = (
  object: AppliedExtraPerUnit | AppliedExtraPerGuest,
): object is AppliedExtraPerGuest => 'adultsQuantity' in object

export const isNotAppliedExtra = (
  object: AppliedExtraPerUnit | AppliedExtraPerGuest | NotAppliedExtra,
): object is NotAppliedExtra =>
  !('quantity' in object || 'adultsQuantity' in object)

export const getApplyingCoupons = (
  couponsMetadata: CouponMetadata[] | undefined,
): CouponMetadata[] | undefined => {
  if (isUndefined(couponsMetadata)) {
    return undefined
  }

  const applyingCoupons = couponsMetadata.filter(
    couponMetadata => couponMetadata.coupon.applies,
  )

  if (isEmpty(applyingCoupons)) {
    return undefined
  }

  return applyingCoupons
}

export const getNonApplyingCoupons = (
  couponsMetadata: CouponMetadata[] | undefined,
): CouponMetadata[] | undefined => {
  if (isUndefined(couponsMetadata)) {
    return undefined
  }

  const nonApplyingCoupons = couponsMetadata.filter(
    couponMetadata => !couponMetadata.coupon.applies,
  )

  if (isEmpty(nonApplyingCoupons)) {
    return undefined
  }

  return nonApplyingCoupons
}

export const getHowCouponsApply = (
  coupons: CouponMetadata[] | undefined,
): HowCouponsApply => {
  if (isUndefined(coupons) || isEmpty(coupons)) {
    return 'none'
  }
  if (
    coupons.every(coupon => {
      return coupon.coupon.applies
    })
  ) {
    return 'every'
  }
  if (coupons.every(coupon => !coupon.coupon.applies)) {
    return 'none'
  }

  return 'some'
}

const createGetCouponByType =
  (type: CouponType) =>
  (coupons: CouponMetadata[] | undefined): string | undefined => {
    return coupons?.find(coupon => coupon.coupon.type === type)?.coupon.value
  }

export const getCouponsPromotional = createGetCouponByType('promotional')

export const getCouponsGroupCode = createGetCouponByType('group')

export const hasPromotionalCouponApplied = (coupons: CouponMetadata[]) => {
  return coupons.some(
    couponMetadata =>
      couponMetadata.coupon.type === 'promotional' &&
      couponMetadata.coupon.applies,
  )
}

export const getMainGuest = (reservation: Reservation) => {
  const roomWithMainGuest = reservation.roomStays.find(roomStay => {
    return roomStay.guest.mainGuest
  })

  const guest = roomWithMainGuest!.guest

  return {
    name: guest.name,
    email: guest.email,
    surname: guest.surname,
  }
}

export const getDateWithYearAndWeekDay = (checkIn?: Date, checkOut?: Date) => {
  const checkInDate = datesManager.formatDateToLocaleWithYearAndWeekDay(checkIn)
  const checkOutDate =
    datesManager.formatDateToLocaleWithYearAndWeekDay(checkOut)

  return { checkInDate, checkOutDate }
}

export const getNights = (checkIn?: Date, checkOut?: Date) => {
  if (isUndefined(checkIn) || isUndefined(checkOut)) {
    return
  }

  return datesManager.calculateNights(checkIn, checkOut)
}
