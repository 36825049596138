import {
  FC,
  MouseEventHandler,
  ReactElement,
  useCallback,
  useEffect,
} from 'react'
import styles from './MobileDatePicker.module.scss'
import datePickerStyles from '../DatePicker.module.scss'
import weekStyles from '../Week.module.scss'
import { Calendar, DateObject } from 'react-multi-date-picker'
import { classNames } from 'src/ui/utils/classnames'
import { weekStartDayIndex } from '../weekStartDayIndex'
import { locales } from '../locales'
import { useDatePicker } from '../DatePickerContext'
import { BLoader } from 'src/ui/components/atoms/BLoader/BLoader'
import { mapDay } from '../mapDay'
import { Time } from 'src/core/Shared/infrastructure/Time'

interface Props {
  renderNav: (
    direction: 'left' | 'right',
    handleMonthChange: MouseEventHandler,
    disabled: boolean,
  ) => ReactElement
  onDatesChange: (dates: DateObject[]) => void
  footer: ReactElement | ReactElement[]
}

export const MobileDatePicker: FC<Props> = ({
  renderNav,
  onDatesChange,
  footer,
}) => {
  const {
    checkIn,
    checkOut,
    locale,
    minDate,
    maxDate,
    range,
    changeVisibleMonths,
    getDayVariant,
    isLoading,
    getPriceRange,
  } = useDatePicker()

  const setCurrentMonth = useCallback(
    (month: Date) => {
      changeVisibleMonths(
        Time.fromDate(month).startOf('month'),
        Time.fromDate(month).endOf('month'),
      )
    },
    [changeVisibleMonths],
  )

  useEffect(
    () => setCurrentMonth(checkIn!),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  return (
    <div className={styles.container}>
      {isLoading && (
        <div className={styles.loadingWrapper}>
          <BLoader />
        </div>
      )}
      <Calendar
        className={classNames(
          checkIn && !checkOut && datePickerStyles.selectedCheckIn,
          checkIn && checkOut && datePickerStyles.selectedDays,
          styles.calendar,
          datePickerStyles.day,
          weekStyles.week,
        )}
        disableMonthPicker
        disableYearPicker
        shadow={false}
        monthYearSeparator=" "
        highlightToday={true}
        numberOfMonths={1}
        maxDate={maxDate}
        minDate={minDate}
        range
        rangeHover
        value={range}
        locale={locales(locale)}
        weekStartDayIndex={weekStartDayIndex(locale)}
        mapDays={({ date }: { date: DateObject }) => {
          const dayVariant = getDayVariant(date.toDate())
          const priceRange = getPriceRange(date.toDate())
          return mapDay(date, dayVariant, priceRange)
        }}
        onChange={onDatesChange}
        renderButton={renderNav}
        plugins={[footer]}
        onMonthChange={date => setCurrentMonth(date.toDate())}
      />
    </div>
  )
}
