import { FC } from 'react'

interface Props {
  color: string
}

export const DatesIcon: FC<Props> = ({ color }) => {
  return (
    <>
      <rect
        x="1"
        y="5.00439"
        width="30.0001"
        height="26.0001"
        rx="1.5"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1 13.0046H31.0001"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.00004 8.00441V1.00439"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.0001 8.00441V1.00439"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  )
}

export default DatesIcon
