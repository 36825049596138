import type {
  CallMeBackType,
  CallMeNowType,
} from 'src/ui/views/_components/CallMe'
import { createModal } from 'src/ui/hooks/useModal'
import { Modal, Text } from 'src/ui/components'
import { useTrans } from 'src/ui/hooks/useTrans'
import { Flex } from 'src/ui/styles/objects/Flex'
import { MyBarceloUniqueLogoVertical } from 'src/ui/views/_components/MyBarceloUniqueLogo'
import { Image } from 'src/ui/components/atoms/Image'

interface Props {
  type: CallMeBackType | CallMeNowType
}

export const CallMeSuccessModal = createModal(({ type }: Props) => {
  const { trans } = useTrans(['common'])
  const isUnique = type === 'unique'
  const isCMNModal = type === 'reservationError' || type === 'paymentError'

  return (
    <Modal size="s" mobileStyle="centered" type="transactional">
      <Text fontStyle="xl-700" as="h2" centered className="mb-m">
        {trans('call-me-success-modal_title')}
      </Text>
      <Text fontStyle="m-300" as="p" centered className="mb-l">
        {isCMNModal
          ? trans('call-me-success-modal_description_CMN')
          : trans('call-me-success-modal_description')}
      </Text>
      <Flex direction="column" alignItems="center" className="mb-xs">
        {isUnique ? (
          <MyBarceloUniqueLogoVertical />
        ) : (
          <Image
            src="/assets/images/main-logo-dark.svg"
            height={56}
            width={160}
            alt="barcelo-logo"
            priority={true}
          />
        )}
      </Flex>
    </Modal>
  )
})
