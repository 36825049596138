import { ForwardRefRenderFunction, forwardRef } from 'react'
import styles from './Alert.module.scss'
import { classNames } from 'src/ui/utils/classnames'
import { Icon } from 'src/ui/components/atoms/Icon/Icon'
import { Flex } from 'src/ui/styles/objects/Flex'
import { Text } from 'src/ui/components/atoms/Text'
import { FontType } from 'src/ui/styles/settings'
import {
  SuccessIconNotificationDark,
  ErrorIconNotificationDark,
  WarningIconNotificationDark,
  InfoIconNotificationDark,
} from 'src/ui/components/atoms/Icon/_icons/notifications'

type AlertTypes = 'warning' | 'info' | 'success' | 'error' | 'ignored'

interface Props {
  type: AlertTypes
  title?: string
  description?: string | JSX.Element
  className?: string
  ariaLabel?: string
  'data-testid'?: string
  descriptionFontStyle?:
    | FontType
    | {
        mobile: FontType
        tablet?: FontType
        laptop?: FontType
        desktop?: FontType
      }
}

const AlertWithRef: ForwardRefRenderFunction<HTMLDivElement, Props> = (
  {
    type,
    title,
    description,
    className,
    ariaLabel,
    'data-testid': testId,
    descriptionFontStyle = 's-300',
  },
  ref,
) => {
  const iconsByType = {
    success: SuccessIconNotificationDark,
    error: ErrorIconNotificationDark,
    warning: WarningIconNotificationDark,
    info: InfoIconNotificationDark,
  }

  const getDescription = () => {
    if (typeof description === 'string') {
      return (
        <Text fontStyle={descriptionFontStyle} color="dark">
          {description}
        </Text>
      )
    }

    return description
  }

  return (
    <div
      ref={ref}
      className={classNames(styles.alertWrapper)}
      aria-label={ariaLabel}
      data-testid={testId}
    >
      <div className={classNames(styles.alert, styles[type], className)}>
        <div role="alert">
          <Flex direction="row" gap="s">
            {type && type !== 'ignored' && (
              <Icon size="l" icon={iconsByType[type]} />
            )}
            <Flex
              direction="column"
              justifyContent="flex-start"
              alignItems="flex-start"
              gap="xxs"
            >
              {title && (
                <Text fontStyle="m-500" color="dark">
                  {title}
                </Text>
              )}
              {description && getDescription()}
            </Flex>
          </Flex>
        </div>
      </div>
    </div>
  )
}

export const Alert = forwardRef(AlertWithRef)
