import type { PropsWithChildren } from 'react'
import type { FieldValues, SubmitHandler, UseFormProps } from 'react-hook-form'
import { useForm, FormProvider } from 'react-hook-form'

interface Props<T extends FieldValues> extends PropsWithChildren {
  config: Config<T>
  className?: string
}

interface Config<T extends FieldValues> extends UseFormProps<T> {
  onSubmit: SubmitHandler<T>
}

export function Form<T extends FieldValues>({
  config: { onSubmit, ...config },
  className,
  children,
}: Props<T>) {
  const messages = useForm<T>(config)
  const { handleSubmit } = messages
  return (
    <FormProvider {...messages}>
      <form
        onSubmit={handleSubmit(onSubmit)}
        autoComplete="on"
        noValidate
        className={className}
      >
        {children}
      </form>
    </FormProvider>
  )
}
