import {
  createContext,
  FC,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import { useApplicationRouter } from 'src/ui/hooks/useApplicationRouter'
import { amountOfAppliedCoupons } from 'src/core/Availability/domain/amountOfAppliedCoupons'
import {
  isDefined,
  isEqual,
  isUndefined,
  noop,
} from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'
import { useUser } from 'src/ui/contexts/UserContext'
import {
  AvailabilityCoupon,
  couponAppliesInAllAvailability,
  couponAppliesInSomeAvailability,
} from 'src/core/Availability/domain/AvailabilityCoupon'
import { useAvailability } from 'src/ui/contexts/AvailabilityContext'
import { useQueryService } from '../hooks/useQuery'
import { PromotionalValidCoupon } from 'src/core/Hotel/domain/PromotionalValidCoupon.model'
import { container } from 'src/core/Shared/_di'
import { CouponValidationResult } from 'src/core/Hotel/domain/CouponValidation.model'
import { useCouponValidator } from 'src/ui/hooks/useCouponValidator'
import { Availability } from 'src/core/Availability/domain/Availability.model'

export type HowCouponAppliesInAvailability = 'every' | 'some' | 'none'

export interface CouponsContext {
  availabilityCoupon?: AvailabilityCoupon
  promotionalCoupon?: PromotionalValidCoupon | undefined
  numberOfCoupons?: number
  hasToShowCouponNotification: boolean
  hideCouponNotification: () => void
  howCouponAppliesInAvailability: HowCouponAppliesInAvailability
  couponValidationResult?: CouponValidationResult
  setCouponValidationResult: (result?: CouponValidationResult) => void
}

const defaultState: CouponsContext = {
  availabilityCoupon: undefined,
  numberOfCoupons: undefined,
  hasToShowCouponNotification: false,
  hideCouponNotification: noop,
  howCouponAppliesInAvailability: 'none',
  couponValidationResult: undefined,
  setCouponValidationResult: noop,
}

export const CouponsContext = createContext<CouponsContext>(defaultState)

interface Props {
  children: ReactNode
}

export const CouponsProvider: FC<Props> = ({ children }) => {
  const [availabilityCoupon, setAvailabilityCoupon] = useState<
    AvailabilityCoupon | undefined
  >(undefined)
  const [showCouponNotification, setShowCouponNotification] = useState(false)
  const hasToShowCouponNotificationRef = useRef(true)
  const [howCouponAppliesInAvailability, setHowCouponAppliesInAvailability] =
    useState<HowCouponAppliesInAvailability>('none')
  const [couponValidationResult, setCouponValidationResult] = useState<
    CouponValidationResult | undefined
  >(undefined)
  const { user } = useUser()
  const { queryUtils, navigate, query } = useApplicationRouter()
  const { availability } = useAvailability()
  const numberOfCoupons = amountOfAppliedCoupons(availabilityCoupon, user)
  const promotionalCouponParam = queryUtils.getCouponParam()
  const { validateCoupon } = useCouponValidator()
  const [lastCoupon, setLastCoupon] = useState<AvailabilityCoupon | undefined>(
    undefined,
  )
  const [lastAvailability, setLastAvailability] = useState<
    Availability | undefined
  >(undefined)

  const getCouponFromQuery = useCallback(async () => {
    const specialCoupon = queryUtils.getRawParam('specialCoupon')
    const groupCoupon = queryUtils.getRawParam('groupcode')

    if (
      isUndefined(promotionalCouponParam) &&
      isUndefined(specialCoupon) &&
      isUndefined(groupCoupon)
    ) {
      return undefined
    }

    if (isDefined(promotionalCouponParam) && isDefined(specialCoupon)) {
      await queryUtils.removeParam('specialCoupon')
      return new AvailabilityCoupon('promotional', promotionalCouponParam)
    }

    if (isDefined(promotionalCouponParam) && isDefined(groupCoupon)) {
      await queryUtils.removeParam('groupcode')
      return new AvailabilityCoupon('promotional', promotionalCouponParam)
    }

    if (isDefined(specialCoupon)) {
      // Esto es un apaño temporal, cuando los cupones especiales se aborden
      // en el futuro esto habrá que cambiarlo
      await navigate.toSameWithReload({ ...query, coupon: specialCoupon })
      return new AvailabilityCoupon('promotional', specialCoupon)
    }

    if (isDefined(groupCoupon)) {
      return new AvailabilityCoupon('group', groupCoupon)
    }

    if (isDefined(promotionalCouponParam)) {
      return new AvailabilityCoupon('promotional', promotionalCouponParam)
    }
  }, [queryUtils, promotionalCouponParam, navigate, query])

  const { data: promotionalCoupon } = useQueryService<
    PromotionalValidCoupon | undefined
  >(
    `get-valid-coupon-${promotionalCouponParam}`,
    [promotionalCouponParam],
    async () => {
      const hotelParam = queryUtils.getRawParam('hotel')
      const checkInParam = queryUtils.getRawParam('depart')

      if (
        isUndefined(promotionalCouponParam) ||
        isUndefined(hotelParam) ||
        isUndefined(checkInParam)
      ) {
        return undefined
      }

      return await container.resolve('getPromotionalValidCoupon')(
        promotionalCouponParam,
        hotelParam,
        checkInParam,
      )
    },
  )

  useEffect(() => {
    const setCouponApplicabilityFromAvailability = async () => {
      const couponFromQuery = await getCouponFromQuery()

      if (isUndefined(couponFromQuery)) {
        setAvailabilityCoupon(undefined)
        setHowCouponAppliesInAvailability('none')
        return
      }

      if (isUndefined(availability)) {
        couponFromQuery?.setNotApplies()
        setHowCouponAppliesInAvailability('none')
        setAvailabilityCoupon(couponFromQuery)
        return
      }

      if (
        isEqual(lastCoupon, couponFromQuery) &&
        isEqual(lastAvailability, availability)
      ) {
        return
      }

      const couponAppliesInEveryRoom = couponAppliesInAllAvailability(
        availability,
        couponFromQuery,
      )
      const couponAppliesInSomeRooms = couponAppliesInSomeAvailability(
        availability,
        couponFromQuery,
      )

      if (couponAppliesInEveryRoom || couponAppliesInSomeRooms) {
        couponFromQuery?.setApplies()
      }

      const couponValidationResultResponse = await validateCoupon(
        couponFromQuery.value,
      )

      setLastCoupon(couponFromQuery)
      setLastAvailability(availability)
      setHowCouponAppliesInAvailability(
        couponAppliesInEveryRoom
          ? 'every'
          : couponAppliesInSomeRooms
            ? 'some'
            : 'none',
      )
      setAvailabilityCoupon(couponFromQuery)
      setCouponValidationResult(couponValidationResultResponse)

      container
        .resolve('analytics')
        .actions.availableRooms.invalidPromotionalCoupon(
          couponFromQuery,
          couponValidationResultResponse,
        )
    }
    setCouponApplicabilityFromAvailability()
  }, [
    availability,
    queryUtils,
    getCouponFromQuery,
    availabilityCoupon?.applies,
  ])

  useEffect(() => {
    setShowCouponNotification(
      isDefined(availability) &&
        isDefined(availabilityCoupon) &&
        hasToShowCouponNotificationRef.current,
    )
  }, [availabilityCoupon, availability])

  useEffect(() => {
    hasToShowCouponNotificationRef.current = true
  }, [availability])

  const hideCouponNotification = () => {
    setShowCouponNotification(false)
    setCouponValidationResult(undefined)
    hasToShowCouponNotificationRef.current = false
  }

  return (
    <CouponsContext.Provider
      value={{
        availabilityCoupon,
        promotionalCoupon,
        numberOfCoupons,
        hasToShowCouponNotification: showCouponNotification,
        hideCouponNotification,
        howCouponAppliesInAvailability,
        couponValidationResult,
        setCouponValidationResult,
      }}
    >
      {children}
    </CouponsContext.Provider>
  )
}

export const useCoupons = () => useContext(CouponsContext)
