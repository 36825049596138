import { container } from 'src/core/Shared/_di'
import { useApplicationRouter } from './useApplicationRouter'

export const useStepper = () => {
  const { pathname } = useApplicationRouter()
  const currentStep = container
    .resolve('stepperManager')
    .getCurrentStep(pathname)

  return { currentStep }
}
