import { container } from 'src/core/Shared/_di'
import { AnalyticsActionsSharedCallMeBack } from 'src/core/Shared/domain/Analytics'

export const callMeBack: AnalyticsActionsSharedCallMeBack = callMeBackData => {
  const linkParams = {
    ...callMeBackData,
  }

  container
    .resolve('logger')
    .info('Analytics/links/actions/shared/callMeBack', {
      linkParams,
      utag_data: window.utag_data,
    })

  window.utag.link(linkParams)
}
