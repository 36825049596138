import { FC } from 'react'

interface Props {
  color: string
}

export const DiamondRoundedIcon: FC<Props> = ({ color }) => (
  <>
    <path
      id="Fill 1"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 11.059C0 17.1565 4.961 22.1175 11.0585 22.1175C17.156 22.1175 22.1175 17.1565 22.1175 11.059C22.1175 4.961 17.156 0 11.0585 0C4.961 0 0 4.961 0 11.059ZM0.650098 11.059C0.650098 5.31949 5.3191 0.649988 11.0586 0.649988C16.7981 0.649988 21.4671 5.31949 21.4671 11.059C21.4671 16.7975 16.7981 21.4665 11.0586 21.4665C5.3191 21.4665 0.650098 16.7975 0.650098 11.059Z"
      fill={color}
    />
    <path
      id="Shape"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.1705 9.2541L17.1246 6.61803L17.0459 6.5H5.4L3.27541 9.2541L3 9.6082L3.27541 9.92295L10.9475 18.5H11.4984L19.4459 9.6082L19.1705 9.2541ZM16.2197 7.12952L14.646 9.13607L12.1673 7.12952H16.2197ZM13.741 9.21475L11.223 7.16885L8.70498 9.21475H13.741ZM10.318 7.12951L7.8 9.13606L6.18688 7.12951H10.318ZM7.0918 9.21475L5.55738 7.28688L4.06229 9.21475H7.0918ZM4.14111 9.88361H7.56406L10.2788 16.7689L4.14111 9.88361ZM8.23272 9.88361L11.2229 17.3984L14.213 9.88361H8.23272ZM12.1673 16.7689L14.882 9.88361H18.305L12.1673 16.7689ZM16.8886 7.32624L15.3542 9.25411H18.3837L16.8886 7.32624Z"
      fill={color}
    />
  </>
)

export default DiamondRoundedIcon
