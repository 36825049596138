import styles from './AvailableRooms.module.scss'
import { FC, Fragment, useEffect } from 'react'
import { Media } from 'src/ui/styles/objects/Media'
import { Alert, Text } from 'src/ui/components'
import {
  AvailabilityRoom,
  AvailabilityRoomTypeFilter,
  AvailabilityStay,
  getTotalNightsFromAvailability,
} from 'src/core/Availability/domain/Availability.model'
import { OnRateSelected } from './AvailableRooms.model'
import {
  isDefined,
  isUndefined,
} from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'
import { useTrans } from 'src/ui/hooks/useTrans'
import { AvailabilityError } from 'src/ui/views/AvailableRooms/AvailabilityError'
import { AvailabilityError as AvailabilityErrorModel } from 'src/core/Availability/domain/AvailabilityError'
import { useCart } from 'src/ui/contexts/CartContext'
import { useCoupons } from 'src/ui/contexts/CouponsContext'
import { MyBarceloBanner } from './MyBarceloBanner'
import { useModal } from 'src/ui/hooks/useModal'
import { ExpiredCartModal } from 'src/ui/views/_components/ExpiredCartModal'
import {
  ReservedRoomAndRate,
  SelectedRoom,
} from 'src/core/Shared/domain/Reservation.model'
import { useHotel } from 'src/ui/hooks/queries/useHotel'
import { Time } from 'src/core/Shared/infrastructure/Time'
import { CouponsNotification } from 'src/ui/views/AvailableRooms/CouponsNotification'
import { Room, RoomSkeleton } from './Room'
import { classNames } from 'src/ui/utils/classnames'
import { RoomTypeFilter } from './RoomTypeFilter'
import { FastBookingMobile } from './FastBookingMobile'
import { FastBookingDesktop } from './FastBookingDesktop'
import { useDatesModal } from './useDatesModal'
import { BookingStepperMobile } from 'src/ui/views/_components/BookingStepperMobile'
import { GroupCodeNotification } from 'src/ui/views/AvailableRooms/GroupCodeNotification'
import { HotelDetailModal } from 'src/ui/views/_components/HotelDetailModal'
import { isFeatureFlagEnabled } from 'src/core/Shared/infrastructure/featureFlags'
import { MyBarceloBannerV2 } from 'src/ui/views/AvailableRooms/MyBarceloBannerV2'
import { MyBarceloMiniBanner } from 'src/ui/views/AvailableRooms/MyBarceloMiniBanner'
import { LiteLandingModal } from './LiteLandingModal'
import { UserLevelV2 } from 'src/core/User/domain/User'
import { useUser } from 'src/ui/contexts/UserContext'

interface Props {
  filteredAvailability: AvailabilityStay | undefined
  allRooms: AvailabilityRoom[] | undefined
  isAvailabilityLoading: boolean
  reservedRate?: ReservedRoomAndRate
  onRateSelected: OnRateSelected
  onFilterAllRoomType: () => void
  onFilterRoomType: (filter: AvailabilityRoomTypeFilter) => void
  onModifyOccupancy: () => void
  onModifyDates: () => void
  onModifyCoupon: () => void
  selectedRoomTypeFilter: AvailabilityRoomTypeFilter | undefined
  error?: AvailabilityErrorModel
  selectedRoom: SelectedRoom | undefined
  isMultiroom: boolean
  reservationInProgress: boolean
}

export const AvailableRooms: FC<Props> = ({
  filteredAvailability,
  allRooms,
  error,
  reservedRate,
  onRateSelected,
  isAvailabilityLoading,
  selectedRoom,
  isMultiroom,
  onFilterAllRoomType,
  onFilterRoomType,
  onModifyOccupancy,
  onModifyDates,
  onModifyCoupon,
  selectedRoomTypeFilter,
  reservationInProgress,
}) => {
  const { trans } = useTrans(['new-reservation'])

  const hotel = useHotel()

  const {
    hasToShowCouponNotification,
    howCouponAppliesInAvailability,
    availabilityCoupon,
    hideCouponNotification,
    couponValidationResult,
  } = useCoupons()

  const isLoading = isUndefined(filteredAvailability) || isAvailabilityLoading

  const totalNightsFromAvailability =
    getTotalNightsFromAvailability(filteredAvailability)
  const isOneNight =
    isDefined(totalNightsFromAvailability) && totalNightsFromAvailability === 1

  const { cart } = useCart()

  const { user } = useUser()

  const { showModal: showExpiredCartModal } = useModal(ExpiredCartModal)

  const { showDatesModal } = useDatesModal()

  const { showModal: showHotelDetailModal } = useModal(HotelDetailModal)

  const {
    showModal: showLiteLandingModal,
    removeModal: removeLiteLandingModal,
  } = useModal(LiteLandingModal)
  const isMyBarceloRevampEnabled = isFeatureFlagEnabled(
    'FF_FEATURE_5847_MY_BARCELO_REVAMP',
  )

  const onHotelDetailClick = () => {
    if (isUndefined(hotel)) {
      return
    }
    showHotelDetailModal({ hotelId: hotel.id })
  }

  useEffect(() => {
    if (isDefined(cart) && cart.isExpired && !cart.isCommitted) {
      showExpiredCartModal()
    }
  }, [showExpiredCartModal, cart])

  // Función pública temporal sólo para la personalización de cupón inválido en paso 1 de CXO
  useEffect(() => {
    //@ts-ignore
    window.cxoOpenDatesModal = () => {
      showDatesModal()
    }

    return () => {
      //@ts-ignore
      delete window.cxoOpenDatesModal
    }
  }, [showDatesModal])

  const hasToShowMyBarceloSmallBanner = (
    index: number,
    filteredAvailability: AvailabilityStay,
  ): boolean => {
    if (index === 0) {
      return false
    }

    const isLastRoom = index + 1 === filteredAvailability.rooms.length

    if (isLastRoom) {
      return false
    }

    const cadence = 3

    return (index + cadence) % cadence === 0
  }

  const renderNotification = () => {
    if (hasToShowCouponNotification && availabilityCoupon) {
      if (availabilityCoupon.isGroup()) {
        return (
          <GroupCodeNotification
            onClose={hideCouponNotification}
            coupon={availabilityCoupon}
            closeAutomaticallyAfter={Time.seconds(10)}
          />
        )
      }

      if (isDefined(couponValidationResult)) {
        return (
          <CouponsNotification
            onClose={hideCouponNotification}
            coupon={availabilityCoupon}
            closeAutomaticallyAfter={Time.seconds(10)}
            howCouponAppliesInAvailability={howCouponAppliesInAvailability}
            couponValidationResult={couponValidationResult}
          />
        )
      }
    }
  }

  const openLiteLandingModal = async () => {
    await showLiteLandingModal({
      userLevel: user?.level ?? UserLevelV2.NotFound,
      onClose: removeLiteLandingModal,
    })
  }

  return (
    <>
      <div className={styles.heroContainer}>
        <Media mobile>
          <div>
            <BookingStepperMobile currentStep={1} />
          </div>
        </Media>

        <div className={styles.title}>
          <Text
            as="h1"
            fontStyle={{
              mobile: 'l-700',
              tablet: '2xl-700',
              laptop: '3xl-700',
            }}
          >
            {trans('available-rooms_title')}
          </Text>
        </div>

        <Media mobile laptop tablet>
          <div>
            <FastBookingMobile
              onModifyOccupancy={onModifyOccupancy}
              onModifyDates={onModifyDates}
              onModifyCoupon={onModifyCoupon}
              onHotelDetailsClick={onHotelDetailClick}
            />
          </div>
        </Media>

        <Media desktop>
          <div>
            <FastBookingDesktop
              onModifyOccupancy={onModifyOccupancy}
              onModifyDates={onModifyDates}
              onModifyCoupon={onModifyCoupon}
              onHotelDetailsClick={onHotelDetailClick}
            />
          </div>
        </Media>
      </div>

      <div className={styles.container}>
        {isUndefined(error) && (
          <div className={styles.filterSectionWrapper}>
            <div className={styles.filterWrapper}>
              <RoomTypeFilter
                isLoading={isLoading}
                rooms={allRooms}
                onRoomTypeSelected={onFilterRoomType}
                onAllSelected={onFilterAllRoomType}
                selectedRoomType={allRooms?.find(
                  room => room.id === selectedRoomTypeFilter,
                )}
              />
            </div>
            {isDefined(hotel?.hotelVerse) && (
              <div className={styles.hotelverseWrapper}>
                <Alert
                  type="info"
                  description={trans('available-rooms_hotel-verse')}
                />
              </div>
            )}
          </div>
        )}
        <div
          className={classNames(styles.bodyContent)}
          data-target-content="available-rooms-body"
        >
          {isDefined(error) ? (
            <AvailabilityError error={error} />
          ) : (
            <>
              {!isLoading ? (
                <section
                  aria-label={trans('available-rooms_rooms-list_aria-label')}
                  className={styles.rooms}
                  data-target-availability-loading={isLoading}
                >
                  {filteredAvailability.rooms.map((room, index) => {
                    const hasToShowBanner = index === 0
                    const hasToShowMiniBanner = hasToShowMyBarceloSmallBanner(
                      index,
                      filteredAvailability,
                    )

                    return (
                      <Fragment key={room.id}>
                        <Room
                          room={room}
                          hotel={hotel}
                          reservedRoomAndRate={reservedRate}
                          onRateSelected={onRateSelected}
                          selectedRoom={selectedRoom}
                          isMultiroom={isMultiroom}
                          isOneNight={isOneNight}
                          totalNightsFromAvailability={
                            totalNightsFromAvailability ?? 0
                          }
                          reservationInProgress={reservationInProgress}
                        />
                        {isMyBarceloRevampEnabled && hasToShowBanner && (
                          <Media laptop tablet mobile>
                            <MyBarceloBannerV2 onClick={openLiteLandingModal} />
                          </Media>
                        )}
                        {isMyBarceloRevampEnabled && hasToShowMiniBanner && (
                          <Media laptop tablet mobile>
                            <div>
                              <MyBarceloMiniBanner />
                            </div>
                          </Media>
                        )}
                      </Fragment>
                    )
                  })}
                </section>
              ) : (
                <section
                  aria-label={trans(
                    'available-rooms_rooms-list-skeleton_aria-label',
                  )}
                  className={styles.rooms}
                  data-target-availability-loading={isLoading}
                >
                  <RoomSkeleton />
                </section>
              )}
            </>
          )}
          {renderNotification()}
        </div>
        <aside
          className={classNames(styles.asideContainer)}
          data-target-content="available-rooms-aside"
        >
          <div className={styles.asideContent}>
            {isMyBarceloRevampEnabled ? (
              <Media desktop>
                <MyBarceloBannerV2 onClick={openLiteLandingModal} />
              </Media>
            ) : (
              <MyBarceloBanner />
            )}
          </div>
        </aside>
      </div>
    </>
  )
}
