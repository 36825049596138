import { FC, ReactNode, useLayoutEffect, useRef, useState } from 'react'
import styles from './ModalContent.module.scss'
import { classNames } from 'src/ui/utils/classnames'
import { isNull } from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'

type ModalSize = 's' | 'm' | 'l' | 'xl'

interface Props {
  isMobileFullscreen: boolean
  size: ModalSize
  type: 'transactional' | 'passive'
  children: ReactNode
  withButtonsFooter: boolean
}

export const ModalContent: FC<Props> = ({
  isMobileFullscreen,
  size,
  type,
  children,
  withButtonsFooter,
}) => {
  const [hasScroll, setHasScroll] = useState(false)
  const modalContent = useRef<HTMLDivElement>(null)

  useLayoutEffect(() => {
    if (isNull(modalContent.current)) {
      return
    }

    if (modalContent.current.scrollHeight > modalContent.current.clientHeight) {
      setHasScroll(true)
    }
  }, [modalContent.current?.clientHeight, modalContent.current?.scrollHeight])

  return (
    <section
      ref={modalContent}
      className={classNames(
        styles.modalContent,
        styles[`modalContent_${size}`],
        isMobileFullscreen && styles.modalContent_isMobileFullscreen,
        type === 'transactional' && styles.modalContent_transactional,
        type === 'passive' && hasScroll && styles.modalContent_hasScroll,
        withButtonsFooter && styles.modalContent_withButtonsFooter,
      )}
    >
      {children}
    </section>
  )
}
