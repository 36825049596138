import type {
  FieldValues,
  FieldPathByValue,
  RegisterOptions,
} from 'react-hook-form'
import type { RadioButtonGroupFieldProps } from 'src/ui/components'
import { RadioButtonGroupField } from 'src/ui/components'
import { Controller } from 'react-hook-form'

interface Props<TFieldValues extends FieldValues, TValue extends string>
  extends Omit<RadioButtonGroupFieldProps<TValue>, 'name'> {
  name: FieldPathByValue<TFieldValues, TValue>
  field: RegisterOptions<TFieldValues>
}

export function FormRadioButtonGroup<
  TFieldValues extends FieldValues,
  TValue extends string,
>({ name, field, ...props }: Props<TFieldValues, TValue>) {
  return (
    <Controller
      name={name}
      rules={field}
      render={({ field, fieldState }) => (
        <RadioButtonGroupField
          name={name}
          onChange={field.onChange}
          onBlur={field.onBlur}
          value={field.value}
          error={fieldState.error}
          {...props}
        />
      )}
    />
  )
}
