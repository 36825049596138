import { FC } from 'react'

import styles from './AcceleratorTag.module.scss'
import { Text } from 'src/ui/components'
import { Icon } from 'src/ui/components/atoms/Icon/Icon'
import ClockIcon from 'src/ui/components/atoms/Icon/_icons/system/accelerator/ClockIcon'
import { classNames } from 'src/ui/utils/classnames'
import { useTrans } from 'src/ui/hooks/useTrans'
import { Variant } from 'src/ui/hooks/useAccelerators'

interface Props {
  variant: Variant
  quantity?: number
}

export const AcceleratorTag: FC<Props> = ({ variant, quantity }) => {
  const { trans } = useTrans(['new-reservation'])

  const getText = () => {
    if (variant === 'lastRoom') {
      return trans('available-rooms_accelerator_last-room')
    }

    if (variant === 'fewRooms') {
      return trans('available-rooms_accelerator_few-rooms', { rooms: quantity })
    }

    return trans('available-rooms_accelerator_viewing-now', {
      viewers: quantity,
    })
  }

  if (variant === 'none') {
    return null
  }

  return (
    <div className={classNames(styles.container, styles[variant])}>
      <Icon
        icon={ClockIcon}
        size="s"
        color={variant === 'viewingNow' ? 'icon-dark' : 'support-error'}
      />
      <Text fontStyle="s-500" className={styles[variant]}>
        {getText()}
      </Text>
    </div>
  )
}
