import { NextRouter } from 'next/router'
import { ParsedUrlQuery } from 'node:querystring'
import { datesManager } from 'src/core/Shared/infrastructure/datesManager'
import { routes } from 'src/ui/navigation/routes'
import { QueryParamsErrors } from './useApplicationRouter.model'
import { QueryParamKey } from './useApplicationRouter.model'
import { container } from 'src/core/Shared/_di'
import { QueryUtils } from './buildQueryUtils'

export interface ApplicationNavigator {
  to: (pathname: string, query: ParsedUrlQuery) => Promise<void>
  toSameWithReload: (query: ParsedUrlQuery) => Promise<boolean>
  toSameWithoutReload: (query: ParsedUrlQuery) => Promise<boolean>
  toSameWithReplace: (query: ParsedUrlQuery) => Promise<boolean>
  toAvailability: (newQuery?: ParsedUrlQuery) => Promise<void>
  toAvailabilityFromStepper: (query: ParsedUrlQuery) => Promise<void>
  toAvailabilityWhenDeletedCart: (query: ParsedUrlQuery) => Promise<void>
  toAvailabilityWhenEdited: (query: ParsedUrlQuery) => Promise<void>
  toAvailabilityOnStaffAndFriendsCouponError: (
    query: ParsedUrlQuery,
  ) => Promise<void>
  toAvailabilityOnNotFoundError: (query: ParsedUrlQuery) => Promise<void>
  toAvailabilityOnCannotEditCouponError: (
    query: ParsedUrlQuery,
  ) => Promise<void>
  toAvailabilityWithDefaultsForErrors: (
    newQuery: ParsedUrlQuery,
    errors: Pick<
      QueryParamsErrors,
      | 'hasGuestError'
      | 'hasDatesError'
      | 'hasMarketPriceError'
      | 'hasCurrencyError'
    >,
  ) => Promise<void>
  toExtrasFromCart: (query: ParsedUrlQuery) => Promise<void>
  toExtrasFromAvailability: () => Promise<void>
  toPersonalDataFromChoosePaymentAutomatically: () => Promise<void>
  toPersonalDataFromStepper: () => Promise<void>
  toPersonalDataFromExtrasAutomatically: () => Promise<void>
  toPersonalDataFromExtrasWithRedirectView: () => Promise<void>
  toPersonalDataFromExtras: () => Promise<void>
  toChoosePaymentFromIngenicoCallback: (query: ParsedUrlQuery) => Promise<void>
  toChoosePaymentFromStepper: () => Promise<void>
  toChoosePaymentFromExtrasAutomatically: () => Promise<void>
  toChoosePaymentFromExtrasWithRedirectView: () => Promise<void>
  toChoosePaymentFromExtrasIfLogged: () => Promise<void>
  toChoosePaymentFromPersonalData: () => Promise<void>
  toChoosePaymentWithError: (error: string) => Promise<void>
  toChoosePaymentWithoutError: () => Promise<void>
  toBookingConfirmationFromChoosePayment: (
    itineraryNumber: string,
  ) => Promise<void>
  toBookingConfirmationOnPaymentAccepted: (
    query: ParsedUrlQuery,
  ) => Promise<void>
  toBookingConfirmationFromIngenicoCallback: (
    query: ParsedUrlQuery,
  ) => Promise<void>
  navigateWhenHavingUser: () => Promise<void>
  toManagement: () => Promise<void>
  toReservationDetail: (itineraryNumber: string) => Promise<void>
  toReservationDetailError: () => Promise<void>
}

export const buildNavigator = (
  nextRouter: NextRouter,
  queryUtils: QueryUtils,
): ApplicationNavigator => {
  const { push, pathname, query: routerQuery, replace } = nextRouter

  return {
    to: async (pathname, query) => {
      await push({ pathname, query }, undefined, {
        shallow: true,
      })
    },
    toSameWithReload: async query => {
      const url = {
        pathname,
        query,
      }
      return await push(url, undefined, {
        shallow: false,
      })
    },
    toSameWithoutReload: async query => {
      const url = {
        pathname,
        query,
      }
      return await push(url, undefined, {
        shallow: true,
      })
    },
    toSameWithReplace: async query => {
      const url = {
        pathname,
        query,
      }
      return await replace(url, undefined, {
        shallow: true,
      })
    },
    toAvailability: async newQuery => {
      await push(
        { pathname: routes.availability, query: newQuery ?? routerQuery },
        undefined,
        {
          shallow: false,
        },
      )
    },
    toAvailabilityFromStepper: async query => {
      await push({ pathname: routes.availability, query }, undefined, {
        shallow: false,
      })
    },
    toAvailabilityWhenDeletedCart: async query => {
      await push({ pathname: routes.availability, query }, undefined, {
        shallow: false,
      })
    },
    toAvailabilityWhenEdited: async query => {
      await push({ pathname: routes.availability, query }, undefined, {
        shallow: false,
      })
    },
    toAvailabilityOnStaffAndFriendsCouponError: async query => {
      await push({ pathname: routes.availability, query }, undefined, {
        shallow: false,
      })
    },
    toAvailabilityOnNotFoundError: async query => {
      await push({ pathname: routes.availability, query }, undefined, {
        shallow: false,
      })
    },
    toAvailabilityOnCannotEditCouponError: async query => {
      await push({ pathname: routes.availability, query }, undefined, {
        shallow: false,
      })
    },
    toAvailabilityWithDefaultsForErrors: async (
      newQuery,
      { hasGuestError, hasDatesError, hasMarketPriceError, hasCurrencyError },
    ) => {
      let query = { ...newQuery }

      if (hasGuestError) {
        query = {
          ...query,
          rooms: undefined,
          adult: '2',
          child: undefined,
          childages: undefined,
        }
      }

      if (hasDatesError) {
        const { arrive, depart } = datesManager.getDefaultDates()
        query = {
          ...query,
          arrive,
          depart,
        }
      }

      if (hasMarketPriceError) {
        query = {
          ...query,
          marketprice: 'ROW',
        }
      }

      if (hasCurrencyError) {
        query = {
          ...query,
          currency: queryUtils.getCurrencyParam(),
        }
      }

      const withoutEmptyParams = (
        query: Record<string, string | string[] | undefined>,
      ) => JSON.parse(JSON.stringify(query))

      await push(
        {
          pathname: routes.availability,
          query: withoutEmptyParams(query),
        },
        undefined,
        {
          shallow: true,
        },
      )
    },
    toExtrasFromCart: async query => {
      await push({
        pathname: routes.extras,
        query: { ...query },
      })
    },
    toExtrasFromAvailability: async () => {
      const paramsToRemove: QueryParamKey[] = [
        'mealplan',
        'fee',
        'coupon',
        'groupcode',
        'roomStay',
      ]

      const newQuery = removeParamsFromQuery(routerQuery, paramsToRemove)

      await push({
        pathname: routes.extras,
        query: { ...newQuery },
      })
    },
    toPersonalDataFromChoosePaymentAutomatically: async () => {
      container.resolve('stepperManager').setPersonalData()
      const url = {
        pathname: routes.personalData,
        query: routerQuery,
      }
      await push(url)
    },
    toPersonalDataFromStepper: async () => {
      container.resolve('stepperManager').setPersonalData()
      const paramsToRemove: QueryParamKey[] = ['coupon']

      const newQuery = removeParamsFromQuery(routerQuery, paramsToRemove)

      const url = {
        pathname: routes.personalData,
        query: { ...newQuery },
      }
      await push(url)
    },
    toPersonalDataFromExtrasAutomatically: async () => {
      container.resolve('stepperManager').setPersonalData()
      const url = {
        pathname: routes.personalData,
        query: routerQuery,
      }
      await replace(url)
    },
    toPersonalDataFromExtrasWithRedirectView: async () => {
      container.resolve('stepperManager').setPersonalData()
      const newQuery = removeParamsFromQuery(routerQuery, 'redirectView')
      const url = {
        pathname: routes.personalData,
        query: newQuery,
      }
      await push(url)
    },
    toPersonalDataFromExtras: async () => {
      container.resolve('stepperManager').setPersonalData()
      const url = {
        pathname: routes.personalData,
        query: routerQuery,
      }
      await push(url)
    },
    toChoosePaymentFromIngenicoCallback: async query => {
      container.resolve('stepperManager').setChoosePayment()
      const url = {
        pathname: routes.choosePayment,
        query,
      }
      await push(url)
    },
    toChoosePaymentFromStepper: async () => {
      container.resolve('stepperManager').setChoosePayment()
      const paramsToRemove: QueryParamKey[] = ['coupon']

      const newQuery = removeParamsFromQuery(routerQuery, paramsToRemove)

      const url = {
        pathname: routes.choosePayment,
        query: { ...newQuery },
      }
      await push(url)
    },
    toChoosePaymentFromExtrasAutomatically: async () => {
      container.resolve('stepperManager').setChoosePayment()
      const url = {
        pathname: routes.choosePayment,
        query: routerQuery,
      }
      await replace(url)
    },
    toChoosePaymentFromExtrasWithRedirectView: async () => {
      const query = removeParamsFromQuery(routerQuery, 'redirectView')
      container.resolve('stepperManager').setChoosePayment()
      const url = {
        pathname: routes.choosePayment,
        query,
      }
      await push(url)
    },
    toChoosePaymentFromExtrasIfLogged: async () => {
      container.resolve('stepperManager').setChoosePayment()
      const url = {
        pathname: routes.choosePayment,
        query: routerQuery,
      }
      await push(url)
    },
    toChoosePaymentFromPersonalData: async () => {
      container.resolve('stepperManager').setChoosePayment()
      const url = {
        pathname: routes.choosePayment,
        query: routerQuery,
      }
      await push(url)
    },
    toChoosePaymentWithError: async error => {
      container.resolve('stepperManager').setChoosePayment()
      const url = {
        pathname: routes.choosePayment,
        query: {
          ...routerQuery,
          paymentStatus: error,
        },
      }
      await push(url)
    },
    toChoosePaymentWithoutError: async () => {
      container.resolve('stepperManager').setChoosePayment()
      const newQuery = removeParamsFromQuery(routerQuery, 'paymentStatus')
      const url = {
        pathname: routes.choosePayment,
        query: newQuery,
      }
      await push(url)
    },
    toBookingConfirmationFromChoosePayment: async itineraryNumber => {
      await push({
        pathname: routes.bookingConfirmation,
        query: {
          ...routerQuery,
          itineraryNumber,
        },
      })
    },
    toBookingConfirmationOnPaymentAccepted: async query => {
      await push({
        pathname: routes.bookingConfirmation,
        query: { ...query },
      })
    },
    toBookingConfirmationFromIngenicoCallback: async query => {
      await push({
        pathname: routes.bookingConfirmation,
        query: { ...query },
      })
    },
    navigateWhenHavingUser: async () => {
      if (pathname === routes.personalData) {
        container.resolve('stepperManager').setChoosePayment()
        const url = {
          pathname: routes.choosePayment,
          query: {
            ...routerQuery,
          },
        }
        await push(url)

        return
      }

      await push(
        {
          pathname: pathname,
          query: { ...routerQuery },
        },
        undefined,
        {
          shallow: true,
        },
      )
    },
    toManagement: async () => {
      await push({
        pathname: routes.management,
        query: {
          ...routerQuery,
        },
      })
    },
    toReservationDetail: async itineraryNumber => {
      await push({
        pathname: routes.reservationDetail.replace(
          ':itineraryNumber',
          itineraryNumber,
        ),
        query: {
          ...routerQuery,
        },
      })
    },
    toReservationDetailError: async () => {
      await push({
        pathname: routes.reservationDetailError,
        query: {
          ...routerQuery,
        },
      })
    },
  }
}

const removeParamsFromQuery = (
  query: ParsedUrlQuery,
  paramToRemove: QueryParamKey | QueryParamKey[],
) => {
  const queryWithoutParam = { ...query }

  if (Array.isArray(paramToRemove)) {
    paramToRemove.forEach(param => {
      delete queryWithoutParam[param]
    })
  } else {
    delete queryWithoutParam[paramToRemove]
  }

  return queryWithoutParam
}
