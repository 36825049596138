import { AsideModal, Modal, Text } from 'src/ui/components'
import styles from './CalendarModal.module.scss'
import { FormProvider } from 'react-hook-form'
import { useDatesRangeForm } from 'src/ui/hooks/useDatesRangeForm'
import { FormEvent, useEffect } from 'react'
import { CheckInCheckOut } from 'src/core/Shared/domain/CheckInCheckOut'
import { useTrans } from 'src/ui/hooks/useTrans'
import { createModal } from 'src/ui/hooks/useModal'
import { DatePickerProvider } from './DatePicker/DatePickerContext'
import { DatePicker } from './DatePicker'
import { useMedia } from 'src/ui/hooks/useMedia'
import { isUndefined } from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'

interface Props {
  hotelId: string
  market: string
  checkIn: Date
  checkOut: Date
  onClose: () => void
  onSubmit: (dates: CheckInCheckOut) => void
  initialError?: boolean
}

export const CalendarModal = createModal(
  ({
    hotelId,
    market,
    checkIn,
    checkOut,
    onClose,
    onSubmit,
    initialError = false,
  }: Props) => {
    const { trans } = useTrans(['new-reservation', 'common'])
    const {
      methods: datesMethods,
      validateFields,
      setManualErrorMessage,
      resetForm,
      areValuesSameAsOld,
    } = useDatesRangeForm({
      checkIn,
      checkOut,
    })
    const { media } = useMedia()
    const isMobileOrTablet = media === 'mobile' || media === 'tablet'

    useEffect(() => {
      if (!initialError) {
        return
      }

      setManualErrorMessage(trans('common:dates-modal_no-dispo-error'))
    }, [initialError])

    const handleSubmit = (event: FormEvent) => {
      event.preventDefault()
      const { checkIn: newCheckIn, checkOut: newCheckOut } =
        datesMethods.getValues('dates')

      if (initialError && areValuesSameAsOld()) {
        if (isUndefined(newCheckIn)) {
          return setManualErrorMessage(
            trans('common:dates-range-form_select-check-in'),
          )
        }
        if (isUndefined(newCheckOut)) {
          return setManualErrorMessage(
            trans('common:dates-range-form_select-check-out'),
          )
        }

        return setManualErrorMessage(
          trans('common:dates-range-form_select-new-dates'),
        )
      }

      validateFields(onSubmit, onClose)
    }

    const handleReset = (event: FormEvent) => {
      event.preventDefault()

      resetForm()
    }

    const renderDatePicker = () => {
      return (
        <FormProvider {...datesMethods}>
          <DatePickerProvider hotelId={hotelId} market={market}>
            <form
              className={styles.form}
              onSubmit={handleSubmit}
              onReset={handleReset}
            >
              <DatePicker />
            </form>
          </DatePickerProvider>
        </FormProvider>
      )
    }

    return (
      <>
        {isMobileOrTablet ? (
          <AsideModal title={trans('occupancy-form_dates_title-select_dates')}>
            <div className={styles.container}>{renderDatePicker()}</div>
          </AsideModal>
        ) : (
          <Modal
            size="xl"
            mobileStyle="fullscreen"
            type="transactional"
            data-testid="dates-modal"
            onClose={onClose}
          >
            <div className={styles.container}>
              <Text as="h1" fontStyle="2xl-700">
                {trans('occupancy-form_dates_title-select_dates')}
              </Text>
              {renderDatePicker()}
            </div>
          </Modal>
        )}
      </>
    )
  },
)
