import { TFunction, useTranslation } from 'next-i18next'
import { Namespace } from 'src/core/Shared/infrastructure/locales/types'

export type Translator = TFunction

const getTranslationKeys = () =>
  process.env.NEXT_PUBLIC_SHOW_TRANSLATION_KEYS === 'only_keys'

interface Trans {
  trans: Translator
  isTranslationReady: boolean
  language: string
}

export const useTrans = (nameSpaces: Namespace[]): Trans => {
  const { t, ready, i18n } = useTranslation(nameSpaces)
  const language = i18n.language

  if (!getTranslationKeys()) {
    return { trans: t, isTranslationReady: ready, language }
  }

  const trans = (term: string) => {
    return term
  }

  // @ts-ignore
  return { trans, isTranslationReady: ready, language }
}
