import { FC, useRef, useState } from 'react'
import {
  FloatingArrow,
  arrow,
  useFloating,
  autoUpdate,
  offset,
  flip,
  shift,
  useHover,
  useFocus,
  useDismiss,
  useRole,
  useInteractions,
  FloatingPortal,
  Placement,
} from '@floating-ui/react'

import styles from './Tooltip.module.scss'
import { CloseIcon } from 'src/ui/components/atoms/Icon/_icons/interaction/CloseIcon'
import { Icon } from 'src/ui/components/atoms/Icon/Icon'
import { Flex } from 'src/ui/styles/objects/Flex'
import { useTrans } from 'src/ui/hooks/useTrans'
import { classNames } from 'src/ui/utils/classnames'

interface Props {
  triggerElement: string | JSX.Element
  isTriggerElementDisabled?: boolean
  tooltipContent: string
  placement?: Placement
  arrowOffset?: number
  yAxisOffset?: number
  triggerButton?: {
    className?: string
    ariaDisabled?: boolean
    testId?: string
    onClick: (e: React.MouseEvent) => void
  }
  tooltipWrapperClassName?: string
  tooltipArrowClassName?: string
  tooltipTextClassName?: string
  showAlways?: boolean
  showCloseButton?: boolean
}

export const Tooltip: FC<Props> = ({
  triggerElement,
  isTriggerElementDisabled = false,
  tooltipContent,
  placement = 'top',
  arrowOffset,
  yAxisOffset = 5,
  triggerButton,
  tooltipWrapperClassName,
  tooltipArrowClassName,
  tooltipTextClassName,
  showAlways = false,
  showCloseButton = true,
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const arrowRef = useRef(null)

  const ARROW_HEIGHT = 12
  const ARROW_WIDTH = 14

  const { trans } = useTrans(['new-reservation'])

  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    placement,
    // Se asegura de que el tooltip siempre esté en pantalla
    whileElementsMounted: autoUpdate,
    middleware: [
      // Distancia entre el baseline del trigger y el inicio del tooltip
      offset(ARROW_HEIGHT + yAxisOffset),
      flip(),
      shift(),
      arrow({
        element: arrowRef,
      }),
    ],
  })

  const hover = useHover(context, { move: false })
  const focus = useFocus(context)
  const dismiss = useDismiss(context)
  const role = useRole(context, { role: 'tooltip' })

  // Mergea las interactions en prop getters
  const { getReferenceProps, getFloatingProps } = useInteractions([
    hover,
    focus,
    dismiss,
    role,
  ])

  const isDisabled = triggerButton?.ariaDisabled || isTriggerElementDisabled

  return (
    <>
      <button
        type="button"
        ref={refs.setReference}
        {...getReferenceProps()}
        className={triggerButton?.className}
        aria-disabled={isDisabled ?? false}
        data-testid={triggerButton?.testId}
        onClick={triggerButton?.onClick}
      >
        {triggerElement}
      </button>
      <FloatingPortal>
        {(isOpen || showAlways) && (
          <div
            className={classNames(styles.tooltip, tooltipWrapperClassName)}
            ref={refs.setFloating}
            style={floatingStyles}
            {...getFloatingProps()}
            onClick={e => {
              e.preventDefault()
              e.stopPropagation()
            }}
          >
            <Flex gap="xxs">
              <label className={tooltipTextClassName}>{tooltipContent}</label>
              {showCloseButton && (
                <button
                  onClick={() => setIsOpen(false)}
                  className={styles.closeButton}
                >
                  <Icon
                    size="m"
                    color="text-light"
                    icon={CloseIcon}
                    ariaLabel={trans('tooltip_close')}
                  />
                </button>
              )}
            </Flex>
            <FloatingArrow
              className={tooltipArrowClassName}
              ref={arrowRef}
              context={context}
              height={ARROW_HEIGHT}
              width={ARROW_WIDTH}
              fill="var(--b-color-tooltip-background)"
              staticOffset={arrowOffset}
            />
          </div>
        )}
      </FloatingPortal>
    </>
  )
}
