import { container } from 'src/core/Shared/_di'
import { AnalyticsActionsSharedCallMeNow } from 'src/core/Shared/domain/Analytics'

export const callMeNow: AnalyticsActionsSharedCallMeNow = callMeNowData => {
  const linkParams = {
    ...callMeNowData,
  }

  container.resolve('logger').info('Analytics/links/actions/shared/callMeNow', {
    linkParams,
    utag_data: window.utag_data,
  })

  window.utag.link(linkParams)
}
