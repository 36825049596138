import {
  isDefined,
  isUndefined,
} from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'

export const mapExtrasAgeRestrictions = (ages: {
  child?: { min?: number; max?: number }
  adult?: { min?: number }
}) => {
  const childAges = ages.child
  const adultAges = ages.adult

  if (
    isUndefined(childAges?.min) &&
    isUndefined(childAges?.max) &&
    isUndefined(adultAges?.min)
  ) {
    return undefined
  }

  return {
    child:
      isDefined(childAges?.min) && isDefined(childAges?.max)
        ? childAges
        : undefined,
    adult: isDefined(adultAges?.min) ? adultAges : undefined,
  }
}
