import type { CallMeFormContentValues } from 'src/ui/views/_components/CallMe/CallMeFormContent'
import type { CallMeNowType } from 'src/ui/views/_components/CallMe'
import { createModal } from 'src/ui/hooks/useModal'
import { Modal } from 'src/ui/components'
import { useTrans } from 'src/ui/hooks/useTrans'
import { CallMeModalHeader } from 'src/ui/views/_components/CallMe/CallMeModalHeader'
import { CallMeFormContent } from 'src/ui/views/_components/CallMe/CallMeFormContent'
import { CallMeFormFooter } from 'src/ui/views/_components/CallMe/CallMeFormFooter'
import { Form } from 'src/ui/views/_components/Form'
import { FormRadioButtonGroup } from 'src/ui/views/_components/FormRadioButtonGroup'
import { FormSubmit } from 'src/ui/views/_components/FormSubmit'
import { Flex } from 'src/ui/styles/objects/Flex'
import { useUser } from 'src/ui/contexts/UserContext'
import { useState } from 'react'

interface Props {
  type: CallMeNowType
  analytic: object
  onSubmit: (data: object) => void
  onCancel: (data: object) => void
}

interface CallMeNowFormValues extends CallMeFormContentValues {
  userWantsCall: Decision
}

enum Decision {
  yes = 'yes',
  no = 'no',
}

export const CallMeNowModal = createModal(
  ({ type, analytic, onSubmit, onCancel }: Props) => {
    const { trans } = useTrans(['common'])
    const { user } = useUser()
    const [selectedValue, setSelectedValue] = useState<Decision | null>(null)

    const getTitle = (type: CallMeNowType) => {
      if (type === 'paymentError') {
        return trans('call-me-now-modal_title-payment-error')
      }
      return trans('call-me-now-modal_title-reservation-error')
    }

    const handleSubmit = (data: CallMeNowFormValues) => {
      const selectedOption = data.userWantsCall

      const callMeNowData = {
        ...analytic,
        isoc_call_me_now: selectedOption,
        ...(selectedOption === Decision.yes && {
          isoc_first_name: data.name,
          isoc_last_name: data.surname,
          user_phone: data.phone,
        }),
      }

      if (selectedOption === Decision.yes) {
        onSubmit(callMeNowData)
      } else {
        onCancel(callMeNowData)
      }
    }

    const handleRadioChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value as Decision
      setSelectedValue(value)
    }

    const hasToShowForm = selectedValue !== Decision.no

    return (
      <Modal
        size="m"
        mobileStyle="centered"
        type="transactional"
        data-quantum-modal="CallMeNowModal"
        withCustomHeader
        withButtonsFooter
      >
        <CallMeModalHeader
          type={type}
          title={getTitle(type)}
          subtitle={trans('call-me-now-modal_subtitle')}
        />
        <Form
          config={{
            onSubmit: handleSubmit,
            defaultValues: {
              name: user?.givenName,
              surname: user?.familyName,
              phone: user?.phoneNumber,
            },
          }}
        >
          <Flex direction="column" gap="m" alignItems="stretch">
            <FormRadioButtonGroup<CallMeNowFormValues, Decision>
              name="userWantsCall"
              field={{
                required: trans('form-input_error_is-required'),
                onChange: e => handleRadioChange(e),
              }}
              labelClassName="mb-m"
              label={trans('call-me-now-modal_client-wants-call-title')}
              options={[
                {
                  value: Decision.yes,
                  label: trans('call-me-now-modal_client-wants-call_true'),
                },
                {
                  value: Decision.no,
                  label: trans('call-me-now-modal_client-wants-call_false'),
                },
              ]}
            />
            {hasToShowForm && <CallMeFormContent />}
          </Flex>
          <CallMeFormFooter>
            <FormSubmit
              type="submit"
              size={{
                mobile: 'fullWidth',
                laptop: 'large',
              }}
            >
              {hasToShowForm
                ? trans('call-me-now-modal_button')
                : trans('call-me-now-modal_cancel')}
            </FormSubmit>
          </CallMeFormFooter>
        </Form>
      </Modal>
    )
  },
)
