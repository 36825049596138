import { useModal } from 'src/ui/hooks/useModal'
import { CallMeNowModal } from './CallMeNowModal'
import { CallMeSuccessModal } from '../CallMeSuccessModal'
import { useCallback, useEffect } from 'react'
import { container } from 'src/core/Shared/_di'
import { CallMeNowType } from '..'

export type CallMeNowWidgetProps = {
  type: CallMeNowType
  analytic: object
}

export const CallMeNowWidget = ({ type, analytic }: CallMeNowWidgetProps) => {
  const { showModal: showCallMeNowModal, removeModal: removeCallMeNowModal } =
    useModal(CallMeNowModal)
  const { showModal: showSuccesModal } = useModal(CallMeSuccessModal)

  const handleSubmit = useCallback(
    (callMeNowData: object) => {
      removeCallMeNowModal()
      showSuccesModal({ type })
      container.resolve('analytics').actions.shared.callMeNow(callMeNowData)
    },
    [removeCallMeNowModal, showSuccesModal, type],
  )

  const handleCancel = useCallback(
    (callMeNowData: object) => {
      removeCallMeNowModal()
      container.resolve('analytics').actions.shared.callMeNow(callMeNowData)
    },
    [removeCallMeNowModal],
  )

  useEffect(() => {
    showCallMeNowModal({
      type,
      analytic,
      onSubmit: handleSubmit,
      onCancel: handleCancel,
    })
  }, [type, analytic, showCallMeNowModal, handleSubmit, handleCancel])

  return <></>
}
