import { ComponentProps, forwardRef, ForwardRefRenderFunction } from 'react'
import { TextField } from 'src/ui/components'
import { useFormContext, useWatch } from 'react-hook-form'

const FormTextFieldWithRef: ForwardRefRenderFunction<
  HTMLInputElement,
  ComponentProps<typeof TextField>
> = (props, ref) => {
  const { getFieldState, formState, resetField } = useFormContext()
  const error = getFieldState(props.name, formState).error
  const value = useWatch({ name: props.name }) ?? ''

  const handleClearInput = () => {
    props.onClearInput?.()
    resetField(props.name)
  }

  return (
    <TextField
      {...props}
      ref={ref}
      onClearInput={handleClearInput}
      error={error}
      value={value}
    />
  )
}

export const FormTextField = forwardRef(FormTextFieldWithRef)
