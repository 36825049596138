import {
  isDefined,
  removeHtmlTags,
} from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'
import {
  AvailabilityExtraPerUnit,
  AvailabilityExtraPerGuest,
} from '../domain/Availability.model'
import { AvailabilityExtraDTO, ExtraTypeDTO } from './Availability.api.dto'
import { mapPriceDTOWithConverted } from './mapPriceDTOWithConverted'
import { mapExtrasAgeRestrictions } from './mapExtrasAgeRestrictions'

export const mapExtras = (
  extras: AvailabilityExtraDTO[],
): Array<AvailabilityExtraPerUnit | AvailabilityExtraPerGuest> =>
  extras.map(extra => {
    const commonProperties = {
      id: extra.id,
      type: extra.type,
      rates: extra.rates ?? [],
      required: extra.required,
      name: extra.name,
      description: removeHtmlTags(extra.description ?? ''),
      maxQuantity: extra.maxQuantity,
      imageUrl: extra.imageUrl,
      featured: Boolean(extra.featured),
      category: extra.category,
    }

    if (isExtraPerUnit(extra.type) || isExtraPerStay(extra.type)) {
      return {
        ...commonProperties,
        ...(isDefined(extra.price) && {
          price: {
            netPrice: mapPriceDTOWithConverted(extra.price.stay.base),
            tax: {
              percentage: extra.price.stay.tax.percentage,
              amount: mapPriceDTOWithConverted(extra.price.stay.tax.amount),
            },
            grossPrice: mapPriceDTOWithConverted(extra.price.stay.total),
          },
        }),
      } as AvailabilityExtraPerUnit
    }

    return {
      ...commonProperties,
      ...(isDefined(extra.adultsPrice) && {
        adultsPrice: {
          netPrice: mapPriceDTOWithConverted(extra.adultsPrice.stay.base),
          tax: {
            percentage: extra.adultsPrice.stay.tax.percentage,
            amount: mapPriceDTOWithConverted(extra.adultsPrice.stay.tax.amount),
          },
          grossPrice: mapPriceDTOWithConverted(extra.adultsPrice.stay.total),
        },
      }),
      ...(isDefined(extra.childrenPrice) && {
        childrenPrice: {
          netPrice: mapPriceDTOWithConverted(extra.childrenPrice.stay.base),
          tax: {
            percentage: extra.childrenPrice.stay.tax.percentage,
            amount: mapPriceDTOWithConverted(
              extra.childrenPrice.stay.tax.amount,
            ),
          },
          grossPrice: mapPriceDTOWithConverted(extra.childrenPrice.stay.total),
        },
      }),
      ...(isDefined(extra.restrictions?.age) && {
        ageRestrictions: mapExtrasAgeRestrictions(extra.restrictions.age),
      }),
    } as AvailabilityExtraPerGuest
  })

const isExtraPerUnit = (type: ExtraTypeDTO): boolean =>
  type === 'PER_UNITS_STAY' ||
  type === 'PER_UNITS_NIGHT' ||
  type === 'PER_PERSON_STAY' ||
  type === 'PER_PERSON_NIGHT'

const isExtraPerStay = (type: ExtraTypeDTO): boolean =>
  type === 'PER_STAY' || type === 'PER_NIGHT'
