import {
  isDefined,
  isEmpty,
  isNull,
  isUndefined,
} from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'
import { HOTEL_DEFAULTS, Hotel } from 'src/core/Hotel/domain/Hotel.model'
import {
  AwardAndCertificateDTO,
  HighlightDTO,
  HotelDTO,
  HotelRoomDTO,
  PoiDTO,
  RoomCapacity,
  TripAdvisorServiceDTO,
} from './Hotel.api.dto'
import { mapDestination } from './mapDestination'
import { mapRooms } from './mapRooms'
import { mapImageWithDomain } from './mapImageWithDomain'
import { mapHotelVerse } from './mapHotelVerse'

const MAX_HIGHLIGHTS = 8

export const mapHotel = (
  hotelDTO: HotelDTO,
  roomsDTO: HotelRoomDTO[],
): Hotel => {
  return {
    id: hotelDTO.sabre_id,
    guestOptions: mapGuestOptions(roomsDTO, hotelDTO),
    name: hotelDTO.name,
    image: getMainImage(hotelDTO.hotelImgMain_dm),
    destination: mapDestination(hotelDTO),
    completeAddress: mapCompleteAddress(hotelDTO),
    city: hotelDTO.city,
    cityTax: mapCityTaxes(hotelDTO),
    contact: {
      phone: hotelDTO.phones?.[0],
      email: hotelDTO.emails?.[0],
    },
    rooms: mapRooms(roomsDTO),
    isAdultsOnly: mapAdultsOnly(hotelDTO),
    highlights: mapHighlights(hotelDTO.highlights),
    highlightTag: hotelDTO.highlight_tag,
    hotelVerse: mapHotelVerse(hotelDTO.sabre_id),
    hotelImages: mapCarouselImages(hotelDTO.hotel_carrousel_paths),
    awards: mapAwardsAndCertificates(hotelDTO.hotel_awards),
    certificates: mapAwardsAndCertificates(hotelDTO.hotel_certificates),
    map: mapMap(hotelDTO),
    pois: mapPois(hotelDTO.highlights_spots),
    review: {
      averageRate: hotelDTO.rate?.toString(),
      count: hotelDTO.reviews,
      items: hotelDTO.tripadvisor_reviews?.map(review => ({
        id: review.id,
        username: review.user.username,
        publishedDate: new Date(review.published_date),
        rating: review.rating,
        text: review.text,
        title: review.title,
      })),
      services: mapTripadvisorServices(hotelDTO.tripadvisor_subratings),
    },
  }
}

const getMainImage = (image: string | undefined) => {
  if (isUndefined(image) || isEmpty(image)) {
    return HOTEL_DEFAULTS().IMAGE
  }

  return mapImageWithDomain(image, HOTEL_DEFAULTS().FALLBACK_IMAGE)
}

const mapGuestOptions = (roomsData: RoomCapacity[], hotelDto: HotelDTO) => {
  const largestRoom = roomsData.reduce(
    (largest, current) => {
      const getMaxValue = (current: number | undefined, largest: number) =>
        isDefined(current) && current > largest ? current : largest

      return {
        guestLimit_total: getMaxValue(
          current.guestLimit_total,
          largest.guestLimit_total!,
        ),
        guestLimit_adults: getMaxValue(
          current.guestLimit_adults,
          largest.guestLimit_adults,
        ),
        guestLimit_children: getMaxValue(
          current.guestLimit_children,
          largest.guestLimit_children,
        ),
      }
    },
    { guestLimit_adults: 0, guestLimit_children: 0, guestLimit_total: 0 },
  )
  const defaultOptions = {
    maxGuests: 3,
    maxAdults: 2,
    maxChildren: 1,
    maxChildAge: 14,
  }

  if (
    isUndefined(largestRoom) ||
    isNull(largestRoom) ||
    largestRoom.guestLimit_total === 0
  ) {
    return defaultOptions
  }

  return {
    maxGuests: largestRoom.guestLimit_total ?? defaultOptions.maxGuests,
    maxAdults: largestRoom.guestLimit_adults ?? defaultOptions.maxAdults,
    maxChildren: largestRoom.guestLimit_children ?? defaultOptions.maxChildren,
    maxChildAge: hotelDto.minimum_age_for_adults ?? defaultOptions.maxChildAge,
  }
}

const mapAdultsOnly = (hotelDto: HotelDTO) => {
  return hotelDto.is_hotel_adults_only ?? false
}

function mapCityTaxes(hotelDTO: HotelDTO): Hotel['cityTax'] | undefined {
  const indicator = hotelDTO.city_taxes_indicator
  const value = hotelDTO.city_taxes_value
  if (isUndefined(indicator) || isUndefined(value)) {
    return undefined
  }

  return {
    indicator,
    value,
  }
}

const mapCarouselImages = (images: (string | null)[] | undefined) => {
  if (isUndefined(images)) {
    return []
  }

  return images.filter(image => !isNull(image) && !isEmpty(image)) as string[]
}

const mapHighlights = (highlights: HighlightDTO[] | undefined) => {
  const topHighlights = highlights?.slice(0, MAX_HIGHLIGHTS)

  return topHighlights?.map(highlight => {
    return {
      icon: `${process.env.NEXT_PUBLIC_CMS_BASE_URL}${highlight.icon}`,
      title: highlight.title,
    }
  })
}

const mapCompleteAddress = (hotelDTO: HotelDTO) => {
  let destination = ''
  if (!isEmpty(hotelDTO.addressLines)) {
    const address = hotelDTO.addressLines.join(', ')
    destination = `${address}, `
  }
  return `${destination}${hotelDTO.city}, ${hotelDTO.country}`
}

const mapAwardsAndCertificates = (
  awardsAndCertificates: AwardAndCertificateDTO[] | undefined,
) => {
  if (isUndefined(awardsAndCertificates) || isEmpty(awardsAndCertificates)) {
    return []
  }

  return awardsAndCertificates.map(item => {
    return {
      title: !isEmpty(item.title) ? item.title : undefined,
      description: item.description,
      logoPath: `${process.env.NEXT_PUBLIC_CMS_BASE_URL}${item.logoPath}`,
    }
  })
}

const mapMap = (hotelDTO: HotelDTO) => {
  return {
    latitude: parseFloat(hotelDTO.latitude),
    longitude: parseFloat(hotelDTO.longitude),
    zoom: parseInt(hotelDTO.static_hotelmap_zoom),
    staticMapImage: `${process.env.NEXT_PUBLIC_CMS_BASE_URL}${hotelDTO.static_hotelmap_image}`,
  }
}

const mapPois = (pois: PoiDTO[] | undefined) => {
  if (isUndefined(pois) || isEmpty(pois)) {
    return []
  }

  return pois.filter(
    poi =>
      poi.category === 'airports' || poi.category === 'trainAndSubwayStations',
  )
}

const mapTripadvisorServices = (
  services: TripAdvisorServiceDTO[] | undefined,
) => {
  if (isUndefined(services) || isEmpty(services)) {
    return []
  }

  return services.map(service => {
    return {
      name: service.localized_name,
      rate: service.value,
    }
  })
}
