import { useEffect, useState } from 'react'
import { isFeatureFlagEnabled } from 'src/core/Shared/infrastructure/featureFlags'
import { CallMeNowType } from 'src/ui/views/_components/CallMe'

export const useCallMeNow = () => {
  const hasToShowCallMeNow = isFeatureFlagEnabled(
    'FF_FEATURE_5396_CALL_ME_BACK',
  )
  const [isWidgetVisible, setWidgetVisible] = useState(false)
  const [utagObject, setUtagObject] = useState({})
  const [modalType, setModalType] = useState<CallMeNowType>('reservationError')

  useEffect(() => {
    if (!hasToShowCallMeNow) {
      return () => {}
    }

    window.cxoOpenCallMeNow = (
      cmnAnalyticObject: object,
      modalType: CallMeNowType = 'reservationError',
    ) => {
      setWidgetVisible(true)
      setUtagObject(cmnAnalyticObject)
      setModalType(modalType)
    }

    return () => {
      delete window.cxoOpenCallMeNow
    }
  }, [hasToShowCallMeNow])

  return { isWidgetVisible, utagObject, modalType }
}
