import { AnalyticsActionsAvailabilityInvalidPromotionalCoupon } from 'src/core/Shared/domain/Analytics'
import { container } from 'src/core/Shared/_di'
import { CouponValidationType } from 'src/core/Hotel/domain/CouponValidation.model'

export const invalidPromotionalCoupon: AnalyticsActionsAvailabilityInvalidPromotionalCoupon =
  (coupon, couponValidationResult) => {
    if (!coupon.isPromotional()) {
      return
    }

    const couponIsValid =
      couponValidationResult.type === CouponValidationType.VALID

    if (couponIsValid && coupon.applies) {
      return
    }

    const linkParams = {
      ga4_event_name: 'Coupon error',
      event: 'Coupon error',
      event_category: 'Error',
      event_action: 'Coupon Error',
      event_label: `${coupon.value} ${couponValidationResult.type}`,
    }

    container
      .resolve('logger')
      .info(
        'Analytics/links/actions/availableRooms/invalidPromotionalCoupon',
        linkParams,
      )

    window.utag.link(linkParams)
  }
