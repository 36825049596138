import { classNames } from 'src/ui/utils/classnames'
import styles from './RadioButtonGroupField.module.scss'
import { RadioButton } from 'src/ui/components/atoms/RadioButton'
import { Text } from 'src/ui/components/atoms/Text'
import { FocusEventHandler, FormEventHandler } from 'react'

export interface RadioButtonGroupFieldProps<T extends string> {
  name: string
  className?: string
  label?: string
  labelClassName?: string
  value?: T
  options: Array<Option<T>>
  optionsClassName?: string
  onChange?: FormEventHandler<HTMLInputElement>
  onBlur?: FocusEventHandler<HTMLInputElement>
  error?: { message?: string }
}

interface Option<T extends string> {
  label: string
  value: T
}

export function RadioButtonGroupField<T extends string = string>({
  className,
  labelClassName,
  optionsClassName,
  label,
  value,
  options,
  error,
  ...props
}: RadioButtonGroupFieldProps<T>) {
  return (
    <div className={className}>
      {label && (
        <div className={classNames(styles.label, labelClassName)}>{label}</div>
      )}
      <div className={classNames(styles.options, optionsClassName)}>
        {options.map(option => (
          <RadioButton
            {...props}
            inputClassName={error && styles.optionError}
            key={option.value}
            value={option.value}
            checked={isSelected(option)}
          >
            <Text fontStyle="m-300">{option.label}</Text>
          </RadioButton>
        ))}
      </div>
      {error?.message && (
        <Text fontStyle="s-300" color="support-error">
          {error.message}
        </Text>
      )}
    </div>
  )

  function isSelected(option: Option<T>) {
    if (!value) {
      return false
    }
    return option.value === value
  }
}
