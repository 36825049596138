interface Props {
  variant?: 'light' | 'dark'
  className?: string
}

export function MyBarceloUniqueLogoHorizontal({
  variant = 'dark',
  className,
}: Props) {
  const fill = `var(--b-color-text-${variant})`
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="205"
      height="25"
      viewBox="0 0 205 25"
      fill="none"
      className={className}
    >
      <g clipPath="url(#clip0_4074_1029)">
        <path
          d="M118.155 4.14479C118.184 4.16079 118.217 4.16879 118.25 4.16879H120.485C120.508 4.16879 120.532 4.16679 120.554 4.15879C120.575 4.15079 120.597 4.14079 120.613 4.12679L124.087 1.38102C124.116 1.35702 124.135 1.32903 124.142 1.29703C124.152 1.26503 124.149 1.23303 124.133 1.20104C124.116 1.16904 124.095 1.14304 124.064 1.12504C124.033 1.10704 123.994 1.09705 123.959 1.09705H120.415C120.384 1.09705 120.353 1.10505 120.325 1.11504C120.296 1.12504 120.275 1.14704 120.256 1.16704L118.091 3.91281C118.071 3.93881 118.06 3.96681 118.06 3.9968C118.06 4.0268 118.067 4.0548 118.081 4.0828C118.098 4.10879 118.122 4.12879 118.15 4.14479H118.153H118.155Z"
          fill={fill}
        />
        <path
          d="M122.14 13.3539C121.72 14.0458 120.978 14.5778 119.688 14.5778C118.398 14.5778 117.676 14.0458 117.259 13.3539C116.844 12.6619 116.746 11.812 116.746 11.178C116.746 10.5441 116.841 9.68417 117.259 8.99023C117.674 8.29429 118.41 7.75833 119.688 7.75833C120.967 7.75833 121.72 8.29429 122.14 8.99023C122.559 9.68617 122.655 10.5421 122.655 11.178C122.655 11.814 122.559 12.6619 122.14 13.3539ZM124.762 6.73242C123.549 5.66851 121.811 5.07056 119.693 5.07056C117.576 5.07056 115.85 5.67051 114.641 6.73242C113.434 7.79633 112.752 9.3222 112.752 11.178C112.752 13.0339 113.434 14.5478 114.641 15.6077C115.847 16.6656 117.585 17.2635 119.693 17.2635C121.801 17.2635 123.549 16.6656 124.762 15.6077C125.976 14.5498 126.658 13.0259 126.658 11.178C126.658 9.3302 125.976 7.79633 124.762 6.73242Z"
          fill={fill}
        />
        <path
          d="M110.818 1.09912H106.994C106.934 1.09912 106.882 1.11912 106.846 1.15112C106.808 1.18511 106.784 1.22911 106.784 1.27911V16.7738C106.784 16.8238 106.808 16.8698 106.846 16.9038C106.884 16.9378 106.936 16.9578 106.994 16.9578H110.818C110.878 16.9578 110.93 16.9378 110.966 16.9038C111.002 16.8698 111.028 16.8258 111.028 16.7738V1.28111C111.028 1.23111 111.004 1.18511 110.966 1.15312C110.928 1.11912 110.876 1.10112 110.818 1.10112V1.09912Z"
          fill={fill}
        />
        <path
          d="M47.5194 13.4881C47.0688 13.7981 46.3892 13.948 45.4783 13.948H41.4558V10.0584H45.5523C46.4393 10.0584 47.0974 10.2244 47.5337 10.5623C47.9701 10.9003 48.187 11.4063 48.187 12.0902C48.187 12.7142 47.9629 13.1801 47.5146 13.4881H47.517H47.5194ZM41.4558 4.11287H44.949C45.805 4.11287 46.4321 4.24086 46.847 4.50284C47.2619 4.76482 47.4622 5.16478 47.4622 5.71074C47.4622 6.39668 47.1618 6.81264 46.7373 7.05662C46.3129 7.3006 45.7597 7.3746 45.2542 7.3746H41.4582V4.11287H41.4558ZM49.5891 8.31452C50.302 7.95055 50.8337 7.50659 51.1866 6.97663C51.5395 6.44667 51.7136 5.83073 51.7136 5.12079C51.7136 3.7669 51.2272 2.76098 50.2376 2.09504C49.2505 1.42909 47.7626 1.09912 45.7597 1.09912H37.2927C37.233 1.09912 37.1806 1.11912 37.1448 1.15112C37.1067 1.18511 37.0828 1.22911 37.0828 1.27911V16.7738C37.0828 16.8238 37.1067 16.8698 37.1448 16.9038C37.183 16.9378 37.2354 16.9578 37.2927 16.9578H46.0125C47.5266 16.9578 49.167 16.6518 50.4284 15.9159C51.6897 15.1779 52.5744 14.01 52.5744 12.2842C52.5744 11.3123 52.3097 10.4743 51.8042 9.80439C51.2987 9.13445 50.5548 8.62849 49.5891 8.31452Z"
          fill={fill}
        />
        <path
          d="M62.4959 12.4359C62.4959 13.2439 62.2765 13.8458 61.833 14.2418C61.3872 14.6398 60.7171 14.8377 59.8111 14.8377C59.1434 14.8377 58.6475 14.7357 58.3232 14.5278C57.9941 14.3198 57.832 14.0078 57.832 13.5858C57.832 13.1639 57.9727 12.8579 58.285 12.6219C58.5974 12.3859 59.0862 12.224 59.7729 12.116C59.9088 12.096 60.0495 12.074 60.1878 12.058C60.3285 12.04 60.4692 12.024 60.6075 12.006C60.9603 11.964 61.3085 11.922 61.6304 11.866C61.9499 11.808 62.2479 11.734 62.4959 11.624V12.4359ZM66.497 8.44228C66.497 7.32437 65.9986 6.48044 65.0091 5.91849C64.0196 5.35453 62.5341 5.07056 60.5669 5.07056C58.8025 5.07056 57.3503 5.38053 56.2797 5.95448C55.2115 6.53243 54.5296 7.37636 54.3031 8.45027C54.2959 8.47427 54.3007 8.50027 54.3031 8.52227C54.3102 8.54627 54.3221 8.56826 54.3364 8.58626C54.3531 8.60626 54.3746 8.62226 54.3961 8.63626C54.4199 8.64826 54.4461 8.65626 54.4747 8.66226L57.987 9.16221C58.0156 9.16821 58.0466 9.16421 58.0728 9.16021C58.1014 9.15421 58.1253 9.14422 58.1515 9.12822C58.173 9.11222 58.1944 9.09422 58.2063 9.07022C58.2183 9.05022 58.2278 9.02423 58.2302 9.00023C58.2898 8.48827 58.4877 8.1103 58.8454 7.86432C59.203 7.61834 59.7109 7.49835 60.3881 7.49835C61.0653 7.49835 61.566 7.55235 61.9308 7.75033C62.2932 7.94832 62.4959 8.28229 62.4959 8.84424C62.5198 9.25821 62.2479 9.50419 61.7353 9.66817C61.2226 9.83216 60.4739 9.91815 59.5392 10.0081C58.2731 10.1321 56.852 10.2701 55.7433 10.7721C54.6369 11.274 53.8381 12.14 53.8238 13.7178C53.8238 14.8117 54.2697 15.6977 55.0732 16.3116C55.8768 16.9256 57.0404 17.2655 58.4734 17.2655C59.3079 17.2655 60.0543 17.1715 60.7243 16.9796C61.3943 16.7876 61.9833 16.4996 62.4983 16.1116V16.2076C62.4983 16.2716 62.4959 16.3476 62.4911 16.4416V16.7796C62.4911 16.8296 62.515 16.8756 62.5531 16.9096C62.5913 16.9436 62.6437 16.9636 62.701 16.9636H66.2871C66.3467 16.9636 66.3992 16.9436 66.435 16.9096C66.4731 16.8756 66.497 16.8316 66.497 16.7796V8.44027V8.44228Z"
          fill={fill}
        />
        <path
          d="M95.5154 9.77824C95.5702 9.45027 95.7204 8.94431 96.1234 8.52034C96.5264 8.09638 97.1845 7.75641 98.2551 7.75641C98.9895 7.75641 99.5474 7.9044 99.9671 8.23037C100.387 8.55834 100.666 9.0623 100.845 9.78024H95.5154V9.77824ZM103.002 6.7145C102.418 6.17654 101.727 5.76657 100.942 5.4906C100.16 5.21462 99.2899 5.07263 98.3528 5.07263C96.3642 5.07263 94.6498 5.71458 93.4314 6.79849C92.213 7.8824 91.4905 9.40827 91.4905 11.1781C91.4905 12.948 92.1796 14.5178 93.3837 15.5878C94.5878 16.6537 96.3022 17.2636 98.3552 17.2636C99.8383 17.2636 101.09 16.9796 102.106 16.4217C103.119 15.8597 103.892 15.0238 104.424 13.9099C104.435 13.8839 104.443 13.8579 104.44 13.8299C104.44 13.8039 104.431 13.7759 104.412 13.7519C104.395 13.7279 104.373 13.7079 104.35 13.6919C104.326 13.6759 104.295 13.6659 104.264 13.6599L101.298 13.238C101.248 13.23 101.202 13.238 101.162 13.2579C101.121 13.2779 101.088 13.3099 101.069 13.3479C100.928 13.6499 100.597 13.9559 100.144 14.1879C99.6905 14.4199 99.1158 14.5758 98.4864 14.5758C97.5469 14.5758 96.8387 14.3779 96.3499 13.9699C95.8611 13.5619 95.5845 12.94 95.5082 12.096H104.116C104.34 12.096 104.54 12.0181 104.686 11.8941C104.831 11.7701 104.922 11.5961 104.922 11.4021V11.3661C104.924 10.4482 104.757 9.57226 104.435 8.78232C104.114 7.99239 103.632 7.28845 103.012 6.71649H103.005L103.002 6.7145Z"
          fill={fill}
        />
        <path
          d="M90.1144 12.8822C90.0977 12.8622 90.0762 12.8442 90.0524 12.8302C90.0285 12.8182 90.0023 12.8062 89.9713 12.8042L86.6141 12.3262C86.5855 12.3202 86.5592 12.3242 86.5306 12.3282C86.502 12.3342 86.4782 12.3442 86.4567 12.3602C86.4352 12.3762 86.4138 12.3922 86.4018 12.4122C86.3899 12.4322 86.378 12.4562 86.3732 12.4802C86.2922 12.9582 86.1038 13.4801 85.7008 13.8861C85.2979 14.2881 84.6803 14.5741 83.7408 14.5741C82.7227 14.5741 82.0312 14.1401 81.5949 13.5061C81.1585 12.8702 80.9773 12.0323 80.9773 11.2203C80.9773 10.9324 81.0107 10.0664 81.3827 9.2705C81.7546 8.47856 82.4652 7.75462 83.8148 7.75462C84.5277 7.75462 85.0833 7.90061 85.4862 8.19459C85.8892 8.48856 86.1443 8.93053 86.2492 9.52648C86.2516 9.55047 86.2659 9.57247 86.2779 9.59447C86.2946 9.61447 86.3112 9.63247 86.3327 9.64646C86.3542 9.66246 86.3828 9.67046 86.4066 9.67446C86.4352 9.68046 86.4615 9.68046 86.4901 9.67446L89.9332 9.1865C89.9928 9.1785 90.0381 9.15051 90.0691 9.11451C90.1001 9.07651 90.1168 9.02852 90.1072 8.97852C89.8831 7.79062 89.1558 6.8127 88.0638 6.13276C86.9717 5.45082 85.5172 5.06885 83.8386 5.06885C81.7546 5.06885 80.0355 5.6828 78.8409 6.77071C77.6463 7.86061 76.9739 9.42448 76.9739 11.3243C76.9739 13.0722 77.6725 14.5561 78.8695 15.6C80.0665 16.6479 81.7642 17.2558 83.7647 17.2558C85.4576 17.2558 86.8883 16.8679 87.9732 16.1459C89.0581 15.424 89.8092 14.3621 90.1406 13.0162C90.1478 12.9922 90.1478 12.9662 90.1406 12.9442C90.1335 12.9202 90.1239 12.8982 90.1072 12.8762L90.1144 12.8822Z"
          fill={fill}
        />
        <path
          d="M77.3414 5.31061C77.3414 5.28461 77.3319 5.26062 77.3176 5.23862C77.3009 5.21462 77.2842 5.19662 77.2627 5.17862C77.2413 5.16262 77.2127 5.15263 77.1817 5.14463C77.0672 5.11863 76.9528 5.10063 76.8431 5.09063C76.7334 5.07863 76.6261 5.07263 76.5212 5.07263C75.6008 5.07263 74.7853 5.24062 74.1082 5.55059C73.431 5.85657 72.8921 6.30253 72.5273 6.85248V5.55859C72.5273 5.5086 72.5035 5.4626 72.4653 5.4306C72.4272 5.3966 72.3747 5.37861 72.3175 5.37861H68.9149C68.8553 5.37861 68.8028 5.3986 68.7671 5.4306C68.7289 5.4646 68.7051 5.5086 68.7051 5.55859V16.7777C68.7051 16.8277 68.7289 16.8736 68.7671 16.9076C68.8052 16.9416 68.8577 16.9616 68.9149 16.9616H72.4939C72.5535 16.9616 72.606 16.9416 72.6418 16.9076C72.6799 16.8736 72.7037 16.8297 72.7037 16.7777V11.7221C72.7037 10.6102 72.9732 9.76424 73.5073 9.19629C74.0414 8.62434 74.845 8.32836 75.906 8.30636C75.9489 8.30636 75.9895 8.29436 76.0229 8.27237C76.0562 8.25037 76.0825 8.22237 76.0968 8.18837L77.3319 5.38661C77.3414 5.36261 77.3486 5.33661 77.3438 5.31061H77.3414Z"
          fill={fill}
        />
        <path
          d="M17.3134 1.09912C15.9329 1.09912 14.7764 1.91105 14.4855 2.99496L14.4784 3.02096C14.476 3.03696 14.4712 3.05296 14.4664 3.06696L11.8698 13.4161C11.8722 9.46242 11.877 3.61691 11.877 3.61691C11.877 2.31902 10.7086 1.18711 9.1635 1.10512C7.69947 1.02713 6.45242 1.86106 6.1496 2.99496L6.14245 3.02096C6.14007 3.03696 6.1353 3.05296 6.13053 3.06696L3.54105 13.3901V2.495C3.54105 1.72307 2.79235 1.09912 1.87435 1.09912C0.956347 1.09912 0.207642 1.72307 0.207642 2.495V16.9658H3.53867V16.9458C3.53867 16.9458 3.54582 16.9398 3.54582 16.9378C3.54582 16.9378 6.82678 3.93488 6.88639 3.65891C6.977 3.24494 7.27743 2.96297 7.71855 2.96297C8.19305 2.96297 8.55071 3.25694 8.55071 3.65891V16.9518L11.8674 16.9618V16.9418H11.8841C11.8841 16.9418 15.1698 3.92888 15.2271 3.65491C15.3177 3.24094 15.6181 2.95897 16.0592 2.95897C16.5337 2.95897 16.8914 3.25294 16.8914 3.65491V16.9598H20.2224V3.53292C20.2224 2.18903 18.9229 1.09912 17.3182 1.09912H17.3134Z"
          fill={fill}
        />
        <path
          d="M31.7731 1.09912C30.9147 1.09912 30.1636 1.59308 29.961 2.29302L26.37 13.988C26.37 13.988 26.3629 13.98 26.3605 13.978C26.3223 14.116 26.2794 14.266 26.2341 14.428L26.01 15.1579C25.9456 15.2679 25.8359 15.3479 25.6833 15.3479C25.4735 15.3479 25.3018 15.2059 25.3018 15.028V2.495C25.2994 1.72307 24.5531 1.09912 23.6327 1.09912C22.7123 1.09912 21.966 1.72307 21.966 2.495V15.102C21.966 16.1859 22.9436 17.1338 24.2336 17.2058C25.4401 17.2698 26.4726 16.5978 26.7515 15.6719L30.3043 4.17686V21.5874C30.3043 22.6393 29.4197 23.5832 28.1798 23.7392C26.6347 23.9312 25.2947 22.9233 25.2947 21.6654V19.3236C25.2947 18.5517 24.5507 17.9277 23.628 17.9277C22.7052 17.9277 21.9612 18.5517 21.9612 19.3236V19.6076C21.9612 22.2974 24.5889 24.4972 27.7959 24.4972C31.0029 24.4972 33.621 22.3034 33.6306 19.6196V2.66099C33.6377 1.79706 32.7984 1.09912 31.7683 1.09912H31.7731Z"
          fill={fill}
        />
        <path
          d="M141.709 15.2339C139.494 15.2339 137.863 13.866 137.863 11.9981V7.14453H138.883V11.9981C138.883 13.41 140.092 14.4239 141.711 14.4239C143.33 14.4239 144.551 13.388 144.551 11.9981V7.14453H145.571V11.9981C145.571 13.866 143.94 15.2339 141.711 15.2339H141.709Z"
          fill={fill}
        />
        <path
          d="M152.036 15.1199H151.018V7.14453H151.9L157.732 13.582H157.773V7.14453H158.793V15.1199H157.909L152.064 8.6824H152.036V15.1199Z"
          fill={fill}
        />
        <path
          d="M165.403 7.14453H164.382V15.1179H165.403V7.14453Z"
          fill={fill}
        />
        <path
          d="M175.257 14.4238C177.432 14.4238 179.144 12.9779 179.144 11.1321C179.144 9.28625 177.432 7.84037 175.257 7.84037C173.083 7.84037 171.371 9.27625 171.371 11.1321C171.371 12.9879 173.097 14.4238 175.257 14.4238ZM179.907 16.0077C180.219 16.0077 180.465 15.9737 180.56 15.9517V16.7256C180.465 16.7476 180.219 16.7816 179.881 16.7816C178.059 16.7816 176.821 15.8477 176.387 15.1178C176.035 15.1978 175.653 15.2318 175.26 15.2318C172.527 15.2318 170.353 13.4099 170.353 11.1301C170.353 8.85029 172.527 7.02844 175.26 7.02844C177.992 7.02844 180.167 8.85029 180.167 11.1301C180.167 12.794 179.025 14.2058 177.379 14.8318C177.677 15.3437 178.562 16.0057 179.907 16.0057V16.0077Z"
          fill={fill}
        />
        <path
          d="M188.822 15.2339C186.607 15.2339 184.976 13.866 184.976 11.9981V7.14453H185.996V11.9981C185.996 13.41 187.205 14.4239 188.824 14.4239C190.443 14.4239 191.664 13.388 191.664 11.9981V7.14453H192.685V11.9981C192.685 13.866 191.054 15.2339 188.824 15.2339H188.822Z"
          fill={fill}
        />
        <path
          d="M204.791 15.1199H198.131V7.14453H204.655V7.95446H199.149V10.5622H203.567V11.3722H199.149V14.3099H204.791V15.1199Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_4074_1029">
          <rect
            width="204.6"
            height="24"
            fill="white"
            transform="translate(0.199219 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}
