import { I18n, useTranslation } from 'next-i18next'
import {
  createContext,
  FC,
  ReactNode,
  useContext,
  useEffect,
  useState,
  useCallback,
} from 'react'
import { container } from 'src/core/Shared/_di'
import {
  DEFAULT_LANGUAGE,
  getDefaultLanguageConfig,
  getCurrentLanguageConfig,
} from 'src/core/Shared/infrastructure/locales/i18nUtils'
import { useApplicationRouter } from 'src/ui/hooks/useApplicationRouter'
import { ApplicationRouter } from 'src/ui/hooks/useApplicationRouter/useApplicationRouter'
import { AvailableLanguageConfig } from 'src/core/Shared/infrastructure/locales/types'
import availableLanguages from 'available-languages.json'
import { Time } from 'src/core/Shared/infrastructure/Time'
import { useCookies } from 'src/ui/hooks/useCookies'
import { useAsyncMutation } from 'src/ui/hooks/useMutation'

const defaultLanguageConfig = getDefaultLanguageConfig()

interface Props {
  children: ReactNode
  setIsLoading: (isLoading: boolean) => void
}

export interface LanguageConfigState {
  language: string
  properties: AvailableLanguageConfig['languageProperties']
  currencyProperties: AvailableLanguageConfig['currencyProperties']
  availableLanguages: AvailableLanguageConfig['languageProperties'][]
}

const defaultState: LanguageConfigState = {
  language: DEFAULT_LANGUAGE,
  properties: defaultLanguageConfig.languageProperties,
  currencyProperties: defaultLanguageConfig.currencyProperties,
  availableLanguages: availableLanguages.map(availableLanguage => {
    return availableLanguage.languageProperties
  }),
}

export const LanguageConfigContext =
  createContext<LanguageConfigState>(defaultState)

const LANGUAGE_PARAM_KEY = 'locale='
const requiredNamespaces = ['common', 'new-reservation']

export const LanguageConfigProvider: FC<Props> = ({
  children,
  setIsLoading,
}) => {
  const [languageConfig, setLanguageConfig] =
    useState<LanguageConfigState>(defaultState)
  const { i18n } = useTranslation(requiredNamespaces, {
    useSuspense: false,
  })

  const { setCookie } = useCookies()

  useEffect(() => {
    const {
      CBE_language: { domain, path, name },
    } = container.resolve('envManager').getCookiesConfig()

    setCookie(name, languageConfig.properties.cbeLanguage, {
      domain,
      path,
    })
  }, [languageConfig.properties.cbeLanguage, setCookie])

  const router = useApplicationRouter()

  const hasLanguageParam = router.asPath.includes(LANGUAGE_PARAM_KEY)

  const onChangeLanguage = useCallback(
    async (language: string) => {
      try {
        document.documentElement.lang = getLangCode(language)
        await container.resolve('changeLanguage')(language)
        const languageConf = getCurrentLanguageConfig()

        document.documentElement.dir = getDir(
          languageConf.languageProperties.isRTL,
        )

        Time.setLocale(languageConf.languageProperties.isoCode)

        setLanguageConfig({
          ...languageConfig,
          language: languageConf.languageProperties.cbeLanguage,
          properties: languageConf.languageProperties,
          currencyProperties: languageConf.currencyProperties,
        })
      } catch (error) {
        throw error
      } finally {
        setIsLoading(false)
      }
    },
    [i18n.language],
  )
  const { performMutation: handleChangeLanguage } = useAsyncMutation(
    'handleChangeLanguage',
    onChangeLanguage,
  )

  useEffect(() => {
    const areNamespacesLoaded =
      i18n.hasLoadedNamespace('new-reservation') &&
      i18n.hasLoadedNamespace('common')

    if (!areNamespacesLoaded) {
      return
    }

    if (!hasLanguageParam) {
      setIsLoading(false)
      return
    }

    const language = extractLanguageParam(router, LANGUAGE_PARAM_KEY)

    if (sameLanguageAsQueryParam(i18n, language)) {
      setIsLoading(false)
      return
    }

    handleChangeLanguage(language)
  }, [router, i18n, hasLanguageParam, setIsLoading, handleChangeLanguage])

  return (
    <LanguageConfigContext.Provider value={languageConfig}>
      {children}
    </LanguageConfigContext.Provider>
  )
}

const sameLanguageAsQueryParam = (i18n: I18n, language: string) =>
  i18n.language === language

const extractLanguageParam = (
  router: ApplicationRouter,
  languageParamKey: string,
) => {
  const lngCodeStartPosition =
    router.asPath.indexOf(languageParamKey) + languageParamKey.length
  const lngCodeEndPosition = lngCodeStartPosition + 5

  return router.asPath.slice(lngCodeStartPosition, lngCodeEndPosition)
}

const getLangCode = (language: string) => {
  return language.substring(0, 2)
}

const getDir = (isRTL: boolean) => {
  if (isRTL) {
    return 'rtl'
  }
  return 'ltr'
}

export const useLanguageConfig = () => useContext(LanguageConfigContext)
