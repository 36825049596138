import type {
  CallMeBackType,
  CallMeNowType,
} from 'src/ui/views/_components/CallMe'
import { MyBarceloUniqueLogoHorizontal } from 'src/ui/views/_components/MyBarceloUniqueLogo'
import { Text } from 'src/ui/components'

interface Props {
  type: CallMeBackType | CallMeNowType
  title: string
  subtitle: string
}

export function CallMeModalHeader({ type, title, subtitle }: Props) {
  const isUnique = type === 'unique'
  return (
    <header className="mt-xl mb-xl">
      {isUnique && <MyBarceloUniqueLogoHorizontal className="mb-m" />}
      <Text fontStyle="2xl-700" as="h2" className="mb-xs">
        {title}
      </Text>
      <Text fontStyle="l-700" as="p">
        {subtitle}
      </Text>
    </header>
  )
}
